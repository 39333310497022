import React from 'react'
import { useTranslation } from 'react-i18next'
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer'
import './CustomerCare.scss'

const CustomerCarePage = () => {
    const { t } = useTranslation()

    return (
        <LayoutContainer>
            <div className="customer-care">
                <p className="customer-care__headline">{t('overlayItems.1.headline')}</p>
                &nbsp;
                <p className="customer-care__description">{t('overlayItems.1.content.0.description')}</p>
                <br />
                <p className="customer-care__title">{t('overlayItems.1.content.1.title')}</p>
                <br />
                <p className="customer-care__description">{t('overlayItems.1.content.1.description.0')}</p>
                <br />
                <p className="customer-care__description">{t('overlayItems.1.content.1.description.1')}</p>
                <br />
                <p className="customer-care__description">{t('overlayItems.1.content.1.description.2')}</p>
            </div>
        </LayoutContainer>
    )
}

export default CustomerCarePage
