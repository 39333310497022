import React from 'react'
import classNames from 'classnames'
import './ArrowButton.scss'

interface Props {
    buttonName?: string | null
    className?: string
    onClick?: (e: React.MouseEvent) => void
}

const ArrowButton = (props: Props) => {
    return (
        <div className={classNames('arrow-button', props.className)}>
            <div className="arbeit__arrow-container" onClick={props.onClick}>
                <span className="arbeit__info">{props.buttonName}</span>
                <div className="arbeit__arrow arbeit__arrow--bg-white"></div>
                <svg
                    className="arbeit__arrow--head arbeit__arrow--white"
                    viewBox="0 0 24 24"
                >
                    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                </svg>
            </div>
        </div>
    )
}

export default ArrowButton
