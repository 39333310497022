import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import { brandIcons } from '../../config/brandIcons'
import { ListWhatYouOffer, ListWhatWeOffer, ListRequirements, ListWhoWeAre } from '../../config/ListItems'
import ReferenceBox from '../molecules/ReferenceBox/ReferenceBox'
import PageLayout from '../organisms/PageLayout/PageLayout'
import './HomePage.scss'
import ContentBox from '../molecules/ContentBox/ContentBox'
import ListItems from '../molecules/ListItems/ListItems'
import ArrowButton from '../atoms/ArrowButton/ArrowButton'
import Card from '../atoms/Card/Card'
import PrimaryButton from '../atoms/PrimaryButton/PrimaryButton'
import OrangeContentBox from '../molecules/OrangeContentBox/OrangeContentBox'
import HomeRemoveWorkImage from '../../assets/home-remote-work.jpg'
import Overlay from '../atoms/Overlay/Overlay'
import FloatingButton from '../atoms/FloatingButton/FloatingButton'
import CustomerCarePage from './overlays/CustomerCarePage'
import useKeyboard from '../../hooks/useKeyboard'
import RegistrationStep1 from '../organisms/RegistrationStep1/RegistrationStep1'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ScrollButton from '../atoms/ScrollButton/ScrollButton'
import pentosquarelight from '../../assets/pen-to-square-light.svg'
import addresscardlight from '../../assets/address-card-light.svg'
import messagesmilelight from '../../assets/message-smile-light.svg'
import img2 from '../../assets/video-conference.jpg'
import img3 from '../../assets/digital-operator-laptop.jpg'
import img4 from '../../assets/digital-operator-mobile-phone.jpg'
import img5 from '../../assets/three-steps-to-become-a-digital-operator.jpg'
import shape1mobile from '../../assets/shape1-mobile.svg'
import shape2mobile from '../../assets/shape2-mobile.svg'
import shape3mobile from '../../assets/shape3-mobile.svg'
import shape1desktop from '../../assets/shape1-desktop.svg'
import shape2desktop from '../../assets/shape2-desktop.svg'
import shape3desktop from '../../assets/shape3-desktop.svg'
import LogisticsPage from './overlays/LogisticsPage'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import videoPoster from '../../assets/video-poster.png'

type OverlayVisible = 'none' | 'CustomerCare' | 'Logistics'

const HomePage = () => {
    const { keyboardShown } = useKeyboard()
    const [toggle, settoggle] = useState('')
    const [openOverlay, setOpenOverlay] = useState<OverlayVisible>('none')
    const [showVideoControls, setShowVideoControls] = useState<boolean>(false)
    const videoRef = useRef<HTMLVideoElement>(null)
    const { t } = useTranslation()

    const handleClickExpand = () => {
        toggle === '' ? settoggle('references__logo--show') : settoggle('')
    }

    const handleClickOverlay = (overlayType: OverlayVisible) => {
        setOpenOverlay(overlayType)
    }

    return (
        <>
            <PageLayout useAnchorLinks={true} isHome={true}>
                <Helmet prioritizeSeoTags>
                    <title>{t('helmet.homepage.title')}</title>
                    <meta name="description" content={`${t('helmet.homepage.description')}`} />
                </Helmet>
                <div className="teaser">
                    <div className="teaser__container">
                        <div className="teaser__headline">
                            <h1 role="figure">
                                {t('teaser.headline.0') + ' '}
                                <span className="teaser__headline-accentblue">{t('teaser.headline.1')}</span>{' '}
                                {t('teaser.headline.2')}
                            </h1>
                            <PrimaryButton className="teaser__button">
                                <AnchorLink href="#registration" offset={100}>
                                    {t('teaser.cta')}
                                </AnchorLink>
                            </PrimaryButton>
                        </div>
                    </div>
                    <div className="teaser__container-2">
                        <img src={HomeRemoveWorkImage} className="teaser__image" alt="" />
                        {/* TODO: remove */}
                        <PrimaryButton className="teaser__container-2__button">
                            <AnchorLink href="#registration" offset={100}>
                                Werde jetzt S-KON Expert
                            </AnchorLink>
                        </PrimaryButton>
                    </div>
                </div>

                <div className="home-page__video-container">
                    <video
                        ref={videoRef}
                        className="home-page__video"
                        controls={showVideoControls}
                        onClick={() => !showVideoControls && videoRef.current?.play()}
                        onPlay={() => setShowVideoControls(true)}
                        poster={videoPoster}
                    >
                        <source
                            src="https://content.ekontor24.net/skon/assets/230904_S-Kon_DigitalOperator.mp4"
                            type="video/mp4"
                        />
                    </video>
                </div>

                <OrangeContentBox title={t('expert.headline')} className="headline--left headline--blue" id="expert">
                    <div>
                        <p>{t('expert.intro.0')}</p>
                        <p>&nbsp;</p>
                        <div className="digital-operator__icon-container">
                            {brandIcons
                                .filter((brandIcon) => [3, 4, 5].includes(brandIcon.id))
                                .map((icon) => (
                                    <img key={icon.id} src={icon.src} className="digital-operator__icon" alt="" />
                                ))}
                        </div>
                        <p>&nbsp;</p>
                        <p>{t('expert.intro.1')}</p>
                    </div>
                </OrangeContentBox>

                {!keyboardShown && <FloatingButton buttonName="Werde jetzt Digital Operator" />}

                <ContentBox
                    title={t('who-we-are.headline')}
                    className="headline--orange content-box--backgroundgray"
                    id="who-we-are"
                >
                    <div className="callcenter">
                        {ListWhoWeAre().map((listMarketing, i) => {
                            return (
                                <div key={i} className="callcenter__card">
                                    <img src={listMarketing.icon} className="callcenter__icon" alt="" />
                                    <div className="callcenter__copy">
                                        <strong className="callcenter__title">{listMarketing.title}</strong>
                                        <br />
                                        {listMarketing.description}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </ContentBox>

                <ReferenceBox title={t('references.headline')} className="headline--blue" id="references">
                    <div className="ref-container">
                        <div className="references">
                            {brandIcons.map((brandIcon) => (
                                <img
                                    key={brandIcon.id}
                                    src={brandIcon.src}
                                    alt={brandIcon.alt}
                                    className={classNames(
                                        `references__logo ${toggle}`,
                                        brandIcon.type === 'webp' && 'references__logo--webp'
                                    )}
                                />
                            ))}
                        </div>
                        <button className="references__toggle-btn" onClick={() => handleClickExpand()}>
                            {toggle === '' ? t('references.show-more') : t('references.show-less')}
                        </button>
                    </div>
                </ReferenceBox>

                <div className="digital-operator-1" id="what-we-need">
                    <div className="digital-operator-1__container">
                        <img src={img2} className="digital-operator-1__image" alt="" />
                    </div>
                    <div className="digital-operator-1__container-2">
                        <div className="digital-operator-1__content">
                            <ContentBox
                                title={t('what-you-offer.headline')}
                                className="digital-operator__headline headline--green headline--left content-box--backgroundgray"
                            >
                                <ListItems lists={ListWhatYouOffer()} />
                            </ContentBox>
                        </div>
                    </div>
                </div>
                <div className="digital-operator-2" id="what-we-offer">
                    <div className="digital-operator-2__container">
                        <img src={img3} className="digital-operator-2__image" alt="" />
                    </div>
                    <div className="digital-operator-2__container-2">
                        <div className="digital-operator-2__content">
                            <ContentBox
                                title={t('what-we-offer.headline')}
                                className="digital-operator__headline headline--magenta headline--left"
                            >
                                <ListItems lists={ListWhatWeOffer()} />
                            </ContentBox>
                        </div>
                    </div>
                </div>
                <div className="digital-operator-3" id="what-you-need">
                    <div className="digital-operator-3__container">
                        <img src={img4} className="digital-operator-3__image" alt="" />
                    </div>
                    <div className="digital-operator-3__container-2">
                        <div className="digital-operator-3__content">
                            <ContentBox
                                title={t('requirements.headline')}
                                className="digital-operator__headline headline--green headline--left content-box--backgroundgray"
                            >
                                <ListItems lists={ListRequirements()} />
                            </ContentBox>
                        </div>
                    </div>
                </div>

                <ContentBox
                    // preTitle="Aktuelle"
                    title={t('current-projects.headline')}
                    id="current-projects"
                    className="headline--orange"
                >
                    <div className="card-container">
                        <Card className="card--orange">
                            <div className="card__content-wrapper">
                                <p className="card-container-headline">{t('overlayItems.0.headline')}</p>
                                <p className="card-container-subline">{t('overlayItems.0.subline')}</p>
                            </div>
                            <ArrowButton
                                className="arbeit__arrow--bg-orange"
                                buttonName={t('current-projects.read-more')}
                                onClick={() => handleClickOverlay('CustomerCare')}
                            />
                        </Card>
                        <Card className="card--blue">
                            <div className="card__content-wrapper">
                                <p className="card-container-headline">{t('overlayItems.1.headline')}</p>
                                <p className="card-container-subline">{t('overlayItems.1.subline')}</p>
                            </div>
                            <ArrowButton
                                buttonName={t('current-projects.read-more')}
                                onClick={() => handleClickOverlay('Logistics')}
                            />
                        </Card>
                    </div>
                </ContentBox>

                <ContentBox
                    preTitle={t('threesteps.pretitle')}
                    title={t('threesteps.headline')}
                    className="headline--blue content-box--backgroundgray"
                    id="threesteps"
                >
                    <div className="digital-operator-4">
                        <div className="digital-operator-4__container">
                            <img src={img5} className="digital-operator-4__image" alt="" />
                        </div>
                        <div className="digital-operator-4__container-2">
                            <div className="registration-2">
                                <div className="shape__container">
                                    <img src={shape1mobile} className="shape__mobile" alt="" />
                                    <img src={shape1desktop} className="shape__desktop" alt="" />
                                    <div className="shape__content-container">
                                        <div className="registration-2__content">
                                            <img
                                                src={pentosquarelight}
                                                className="registration-2__content__icon"
                                                alt=""
                                            />
                                            <p className="registration-2__content__headline">
                                                {t('threesteps.step.1.headline')}
                                            </p>
                                            <p className="registration-2__content__subline registration-2__content__subline--left">
                                                {t('threesteps.step.1.text')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="shape__container">
                                    <img src={shape2mobile} className="shape__mobile" alt="" />
                                    <img src={shape2desktop} className="shape__desktop" alt="" />
                                    <div className="shape__content-container">
                                        <div className="registration-2__content">
                                            <img
                                                src={addresscardlight}
                                                className="registration-2__content__icon-2"
                                                alt=""
                                            />
                                            <p className="registration-2__content__headline">
                                                {t('threesteps.step.2.headline')}
                                            </p>
                                            <p className="registration-2__content__subline">
                                                {t('threesteps.step.2.text')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="shape__container">
                                    <img src={shape3mobile} className="shape__mobile" alt="" />
                                    <img src={shape3desktop} className="shape__desktop" alt="" />
                                    <div className="shape__content-container">
                                        <div className="registration-2__content registration-2__content--verificationcall">
                                            <img
                                                src={messagesmilelight}
                                                className="registration-2__content__icon-3"
                                                alt=""
                                            />
                                            <p className="registration-2__content__headline">
                                                {t('threesteps.step.3.headline')}
                                            </p>
                                            <p className="registration-2__content__subline registration-2__content__subline--right">
                                                {t('threesteps.step.3.text')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentBox>
                <ContentBox id="registration">
                    <RegistrationStep1 />
                </ContentBox>
                <Overlay open={openOverlay === 'CustomerCare'} onClick={() => setOpenOverlay('none')}>
                    <CustomerCarePage />
                </Overlay>
                <Overlay open={openOverlay === 'Logistics'} onClick={() => setOpenOverlay('none')}>
                    <LogisticsPage />
                </Overlay>
                <ScrollButton />
            </PageLayout>
        </>
    )
}

export default HomePage
