import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from '../languages/en.json'
import de from '../languages/de.json'

const resources = {
    en: {
        translation: en,
    },
    de: {
        translation: de,
    },
}

export const languages = Object.entries(resources).map(([lang]) => lang)

export const removeLngPrefix = (pathname: string) => {
    for (let lang of languages) {
        if (pathname.startsWith(`/${lang}/`) || pathname === `/${lang}`) {
            return pathname.replace(`/${lang}`, '')
        }
    }
    return pathname
}

export const getLngFromUrl = (pathname: string) => {
    for (let lang of languages) {
        if (pathname.startsWith(`/${lang}/`) || pathname === `/${lang}`) {
            return lang
        }
    }
    return null
}

// Note: Hyperlink returns undefined, added localstorage as a fallback
const lng = getLngFromUrl(window.location.pathname) || i18next.language || (localStorage.getItem("lng") ?? undefined)

const fallbackLng = ['de']

i18next.use(initReactI18next).init({
    fallbackLng,
    resources: resources,
    lng,
})

export default i18next
