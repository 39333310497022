import React, { CSSProperties, ReactNode } from 'react'
import classNames from 'classnames'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useTranslation } from 'react-i18next'

interface Props {
    id?: string
    href: string
    className?: string
    offset?: string
    useAnchorLink: boolean
    children?: ReactNode
    style?: CSSProperties
}

const DynamicLink = (props: Props) => {
    const {
        i18n: { language },
    } = useTranslation()

    const link = props.href.replace(/^\/[a-z]{2}\//, '').replace(/^\//, `/${language}/`)

    if (props.useAnchorLink) {
        return (
            <AnchorLink
                href={'#' + link.split('#')[1]}
                offset={props.offset}
                className={classNames('dynamic-link', props.className)}
                id={props.id}
                style={props.style}
            >
                {props.children}
            </AnchorLink>
        )
    } else {
        return (
            <a href={link} className={classNames('dynamic-link', props.className)} id={props.id} style={props.style}>
                {props.children}
            </a>
        )
    }
}

export default DynamicLink
