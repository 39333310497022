import React from 'react'
import classNames from 'classnames'
import './ListItems.scss'
import ListItem from '../../atoms/ListItem/ListItem'

interface Props {
    className?: string
    lists: any[]
}

const ListItems = (props: Props) => {
    return (
        <ul className={classNames('list-items', props.className)}>
            {props.lists.map((list, i) => (
                <ListItem key={i} list={list} />
            ))}
        </ul>
    )
}

export default ListItems
