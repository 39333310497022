import { actionIds, BaseAction } from '../common'

const setFormData: (formData) => BaseAction = (formData) => ({
    type: actionIds.SET_FORM_DATA,
    payload: {
        ...formData,
    },
})

export default setFormData
