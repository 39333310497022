import React from 'react'
import SvgComponent from '../SvgComponent/SvgComponent'
import './ListItem.scss'

interface Props {
    list: any
}

const ListItem = (props: Props) => (
    <li className="list-item">
        <SvgComponent icon={props.list.icon} color={props.list.color} className="list-item__icon" />
        <p className="list-item__text">{props.list.text}</p>
    </li>
)

export default ListItem
