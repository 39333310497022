import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import RouterWrapper from './RouterWrapper'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import rootReducer from './state/reducers'
import rootSaga from './state/sagas'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import queryClient from './config/queryClient'
import storage from 'redux-persist/es/storage'
import { persistReducer, persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

const persistConfig = {
    key: 'skongoeseurope',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// Browser History for use with react router
const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
const store = createStore(
    persistedReducer,
    undefined,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
)
const persistor = persistStore(store)
sagaMiddleware.run(rootSaga, { history })

const App = () => {
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <PersistGate persistor={persistor}>
                    <RouterWrapper history={history} />
                </PersistGate>
            </QueryClientProvider>
        </Provider>
    )
}

export default App
