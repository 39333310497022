import React, { CSSProperties, ReactNode } from 'react'
import classNames from 'classnames'
import Headline from '../../atoms/Headline/Headline'
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer'
import './ContentBox.scss'

interface Props {
    id?: string
    preTitle?: string | null
    title?: string | null
    className?: string
    children?: ReactNode
    style?: CSSProperties
}

const ContentBox = (props: Props) => {
    return (
        <div
            className={classNames('content-box', props.className)}
            id={props.id}
            style={props.style}
        >
            <LayoutContainer>
                {props.title && (
                    <Headline
                        className={props.className}
                        preTitle={props.preTitle}
                    >
                        {props.title}
                    </Headline>
                )}
                {props.children}
            </LayoutContainer>
        </div>
    )
}

export default ContentBox
