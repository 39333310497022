import React from 'react'
import classNames from 'classnames'
import './FooterInfoText.scss'
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer'
import { useTranslation } from 'react-i18next'

interface Props {
    className?: string
    isHome?: boolean
}

const FooterInfoText = (props: Props) => {
    const { t } = useTranslation()
    return (
        <div className={classNames('footer-info-text', props.className, props.isHome ? 'footer-info-text--home' : '')}>
            <LayoutContainer>
                <div className="footer-info-text__content">
                    <sup>1</sup> {t('footer-info-text')}
                </div>
            </LayoutContainer>
        </div>
    )
}

export default FooterInfoText
