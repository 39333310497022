import React from 'react'
import PageLayout from '../organisms/PageLayout/PageLayout'
import './HomePage.scss'
import ContentBox from '../molecules/ContentBox/ContentBox'
import './RegistrationPage.scss'
import RegistrationStep6 from '../organisms/RegistrationStep6/RegistrationStep6'

const RegistrationPage = () => {
    return (
        <div className="registration-page">
            <PageLayout>
                <ContentBox className="headline--blue" id="registration">
                    <div className="registration">
                        <RegistrationStep6 />
                    </div>
                </ContentBox>
            </PageLayout>
        </div>
    )
}

export default RegistrationPage
