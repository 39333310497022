import React, { ChangeEvent, ReactNode } from 'react'
import classNames from 'classnames'
import { styled } from '@mui/material/styles'
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, RadioProps } from '@mui/material'
import './FormRadioGroup.scss'

const EingabeRadioIcon = styled('span')({
    borderRadius: '50%',
    width: 19.5,
    height: 19.5,
    boxShadow: 'inset 0 0 0 1px #a9a7aa, inset 0 -1px 0 #a9a7aa',
    'input:hover ~ &': {
        backgroundImage: 'radial-gradient(#c4c4c4,#c4c4c4 50%,transparent 32%)',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: '#d9dada',
    },
})

const EingabeRadioCheckedIcon = styled(EingabeRadioIcon)({
    boxShadow: 'inset 0 0 0 1px #00c896, inset 0 -1px 0 #00c896',
    '&:before': {
        display: 'block',
        width: 19.5,
        height: 19.5,
        backgroundImage: 'radial-gradient(#00c896,#00c896 50%,transparent 32%)',
        content: '""',
    },
})

function EingabeRadio(props: RadioProps) {
    return (
        <Radio
            disableRipple
            checkedIcon={<EingabeRadioCheckedIcon />}
            icon={<EingabeRadioIcon />}
            {...props}
        />
    )
}

interface Props {
    className?: string
    name?: string
    label?: string | ReactNode
    helper?: string
    error?: string
    onChange?: (evt: ChangeEvent<HTMLInputElement>) => void
    value?: string
}

const FormRadioGroup = (props: Props) => {
    return (
        <div className={classNames('form-radiogroup', props.className)}>
            <FormControl
                error={Boolean(props.error)}
                sx={{
                    '& .MuiFormHelperText-root': {
                        margin: 0,
                        fontSize: '10px',
                        color: '#21333a',
                        '&.Mui-error': {
                            color: '#f04678',
                        },
                    },
                    '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                        fontFamily: 'Hind, sans-serif',
                        fontWeight: 300,
                        marginTop: 0.3 // align radio button label
                    }
                }}
            >
                {props.label && <div className="form-radiogroup__label">{props.label}</div>}
                <RadioGroup
                    row
                    defaultValue='Ja'
                    aria-labelledby='radio-group'
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                >
                    <FormControlLabel value='yes' control={<EingabeRadio />} label='Ja' />
                    <FormControlLabel value='no' control={<EingabeRadio />} label='Nein' />
                    <FormControlLabel value='unknown' control={<EingabeRadio />} label='Unbekannt' />
                </RadioGroup>
                <FormHelperText>
                    {props.error ? (
                        <>{props.error}</>
                    ) : props.helper ? (
                        <>{props.helper}</>
                    ) : (
                        '\u00a0'
                    )}
                </FormHelperText>
            </FormControl>
        </div>
    )
}

export default FormRadioGroup
