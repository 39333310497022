import React, { ReactNode } from 'react'
import classNames from 'classnames'
import Headline from '../../atoms/Headline/Headline'
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer'
import './OrangeContentBox.scss'
// import WhoWeAreIllustrationImage from '../../../assets/who-we-are-illustration.png'
import img1 from '../../../assets/temp/img1.jpeg'
import headsetcircle from '../../../assets/headsetcircle.svg'
import moneybillcircle from '../../../assets/money-bill-circle.svg'
import housechimney from '../../../assets/house-chimney.svg'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {useTranslation} from "react-i18next";

interface Props {
    id?: string
    preTitle?: string
    title: string
    className?: string
    children?: ReactNode
}

const ContentBox = (props: Props) => {
    const { t } = useTranslation()
    return (
        <div
            className={classNames(
                'orange-content-box-container',
                props.className
            )}
            id={props.id}
        >
            <LayoutContainer>
                <div className="orange-content-box">
                    <div className="orange-content-box__wrapper">
                        <div className='orange-content-box__square-top' />
                        <div className='orange-content-box__square-bottom' />
                        <img
                            src={headsetcircle}
                            className="orange-content-box__icon-headset"
                            alt=""
                        />
                        <img
                            src={moneybillcircle}
                            className="orange-content-box__icon-bill"
                            alt=""
                        />
                        <div className="orange-content-box__rect">
                            <img
                                src={housechimney}
                                className="orange-content-box__icon-house"
                                alt=""
                            />
                            <p className="orange-content-box__text">{t('expert.remote-work')}</p>
                        </div>
                        <div className='orange-content-box__illustration-container'>
                            <img
                                src={img1}
                                alt="Wer wir sind"
                                className="orange-content-box__illustration"
                            />
                        </div>
                        <AnchorLink href="#registration" offset={100}>
                            <PrimaryButton
                                className="orange-content-box__register-button"
                            >
                                {t('expert.cta')}
                            </PrimaryButton>
                        </AnchorLink>
                    </div>
                    <div>
                        <Headline
                            className={props.className}
                            preTitle={props.preTitle}
                        >
                            {props.title}
                        </Headline>
                        {props.children}
                    </div>
                </div>
            </LayoutContainer>
        </div>
    )
}

export default ContentBox
