import { ICountry } from '../../types/ICountry'
import { actionIds, BaseAction } from '../common'

const setCountriesAction: (countries: ICountry) => BaseAction = (
    countries
) => ({
    type: actionIds.SET_COUNTRIES,
    payload: countries,
})

export default setCountriesAction
