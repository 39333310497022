import React from 'react'
import classNames from 'classnames'
import './WizardProgress.scss'

interface Props {
    activeStep: number
    steps: string[]
    screenReaderLabel: string
    className?: string
    onClick: (e: number) => void
}

const WizardProgress = (props: Props) => {
    if (props.activeStep > props.steps.length - 1) {
        console.error(
            'WizardProgress called with invalid step',
            props.activeStep,
            ' (maximum length:',
            props.steps.length,
            ')'
        )
    }

    return (
        <dl className={classNames('wizard-progress', props.className)}>
            <dt className="sr-only">{props.screenReaderLabel}</dt>
            {props.steps.map((step, index) => (
                <dd
                    key={index}
                    className={classNames(
                        'wizard-progress__item',
                        index === props.activeStep
                            ? 'wizard-progress__item--active'
                            : ''
                    )}
                    onClick={() => props.onClick(index)}
                >
                    {step}
                </dd>
            ))}
        </dl>
    )
}

export default WizardProgress
