import React, { useEffect } from 'react'
import PageLayout from '../organisms/PageLayout/PageLayout'
import ContentBox from '../molecules/ContentBox/ContentBox'
import './PrivacyPage.scss'

const PrivacyPage = () => {
    const useScript = (url) => {
        useEffect(() => {
            const script = document.createElement('script')

            script.src = url
            script.async = true

            document.body.appendChild(script)

            return () => {
                document.body.removeChild(script)
            }
        }, [url])
    }

    useScript('https://delivery.consentmanager.net/delivery/vendorlist.php?cdid=ebdaf59fd7ea&l=de')
    useScript('https://delivery.consentmanager.net/delivery/cookieinfo.php?cdid=ebdaf59fd7ea&l=de')

    return (
        <div className="privacy-page">
            <PageLayout>
                <ContentBox className="headline--blue" id="privacy">
                    <h1>Datenschutz</h1>
                    <hr className="seperator magenta" />
                    <h2 className="title blue">Allgemein</h2>
                    <p>
                        Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders
                        hohen Stellenwert für die Geschäftsleitung der S-KON Gruppe. Eine Nutzung der Internetseiten der
                        S-KON Gruppe ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich. Sofern eine
                        betroffene Person besondere Services unseres Unternehmens über unsere Internetseite in Anspruch
                        nehmen möchte, könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist
                        die Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung
                        keine gesetzliche Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein.
                        <br />
                        <br />
                        Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift,
                        E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt stets im Einklang mit der
                        Datenschutz-Grundverordnung und in Übereinstimmung mit den für die S-KON Gruppe geltenden
                        landesspezifischen Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte unser
                        Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der von uns erhobenen, genutzten und
                        verarbeiteten personenbezogenen Daten informieren. Ferner werden betroffene Personen mittels
                        dieser Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.
                        <br />
                        <br />
                        Die S-KON Gruppe hat als für die Verarbeitung Verantwortlicher zahlreiche technische und
                        organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese
                        Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können
                        Internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein
                        absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es jeder betroffenen
                        Person frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch, an
                        uns zu übermitteln.
                        <br />
                    </p>
                    <h2 className="title blue">1. Begriffsbestimmungen</h2>
                    <p>
                        Die Datenschutzerklärung der S-KON Gruppe beruht auf den Begrifflichkeiten, die durch den
                        Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung
                        (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als
                        auch für unsere Kunden und Geschäftspartner einfach lesbar und verständlich sein. Um dies zu
                        gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.
                        <br />
                        <br />
                        Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:
                        <br />
                        <br />
                        <h2 className="title blue">a) personenbezogene Daten</h2>
                    </p>
                    <p>
                        Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
                        identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen. Als
                        identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere
                        mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu
                        einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der
                        physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder
                        sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.
                    </p>
                    <h2 className="title blue">b) betroffene Person</h2>
                    <p>
                        Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren
                        personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
                    </p>
                    <h2 className="title blue">c) Verarbeitung</h2>
                    <p>
                        Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder
                        jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das
                        Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das
                        Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder
                        eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das
                        Löschen oder die Vernichtung.
                    </p>
                    <h2 className="title blue">d) Einschränkung der Verarbeitung</h2>
                    <p>
                        Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem
                        Ziel, ihre künftige Verarbeitung einzuschränken.
                    </p>
                    <h2 className="title blue">e) Profiling</h2>
                    <p>
                        Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin
                        besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte,
                        die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte bezüglich
                        Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen,
                        Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu
                        analysieren oder vorherzusagen.
                    </p>
                    <h2 className="title blue">f) Pseudonymisierung</h2>
                    <p>
                        Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die
                        personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer
                        spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen
                        Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen
                        unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten
                        oder identifizierbaren natürlichen Person zugewiesen werden.
                    </p>
                    <h2 className="title blue">g) Verantwortlicher oder für die Verarbeitung Verantwortlicher</h2>
                    <p>
                        Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische
                        Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die
                        Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet. Sind die Zwecke und
                        Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben,
                        so kann der Verantwortliche beziehungsweise können die bestimmten Kriterien seiner Benennung
                        nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.
                    </p>
                    <h2 className="title blue">h) Auftragsverarbeiter</h2>
                    <p>
                        Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
                        andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
                    </p>
                    <h2 className="title blue">i) Empfänger</h2>
                    <p>
                        Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
                        der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen
                        Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach
                        dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten
                        erhalten, gelten jedoch nicht als Empfänger.
                    </p>
                    <h2 className="title blue">j) Dritter</h2>
                    <p>
                        Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle
                        außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen,
                        die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters
                        befugt sind, die personenbezogenen Daten zu verarbeiten.
                    </p>
                    <h2 className="title blue">k) Einwilligung</h2>
                    <p>
                        Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in
                        informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung
                        oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu
                        verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten
                        einverstanden ist.
                    </p>
                    <p></p>
                    <h2 className="title blue">2. Name und Anschrift des für die Verarbeitung Verantwortlichen</h2>
                    <p>
                        Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedsstaaten der
                        Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit
                        datenschutzrechtlichem Charakter ist die:
                        <br />
                        <br />
                        <div>S-KON New Business AG</div>
                        <div>S-KON Gruppe</div>
                        <div>S-KON Sales Kontor Hamburg GmbH</div>
                        <div>S-KON Management Kontor GmbH</div>
                        <div>(weiterhin als S-KON Gruppe oder SKON bezeichnet)</div>
                        <div>Gasstraße 4c</div>
                        <div>22761 Hamburg</div>
                    </p>
                    <h2 className="title blue">3. Kontakt des Datenschutzbeauftragten</h2>
                    <p>
                        Der Datenschutzbeauftragte der S-KON Gruppe ist erreichbar unter:{' '}
                        <a href="mailto:datenschutz@skon.de">datenschutz@skon.de</a>. Jede betroffene Person kann sich
                        jederzeit bei allen Fragen und Anregungen zum Datenschutz direkt an unseren
                        Datenschutzbeauftragten wenden.
                    </p>
                    <h2 className="title blue">4. Drittanbieter und Cookies</h2>
                    <p>
                        Die Internetseiten der S-KON Gruppe verwenden Cookies. Cookies sind Textdateien, welche über
                        einen Internetbrowser auf einem Computersystem abgelegt und gespeichert werden.
                        <br />
                        <br />
                        Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte
                        Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer
                        Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet
                        werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten
                        Internetseiten und Servern, den individuellen Browser der betroffenen Person von anderen
                        Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser
                        kann über die eindeutige Cookie-ID wiedererkannt und identifiziert werden.
                        <br />
                        <br />
                        Durch den Einsatz von Cookies kann die S-KON Gruppe den Nutzern dieser Internetseite
                        nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht möglich wären.
                        <br />
                        <br />
                        Mittels eines Cookies können die Informationen und Angebote auf unserer Internetseite im Sinne
                        des Benutzers optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt, die Benutzer
                        unserer Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es, den Nutzern die
                        Verwendung unserer Internetseite zu erleichtern. Der Benutzer einer Internetseite, die Cookies
                        verwendet, muss beispielsweise nicht bei jedem Besuch der Internetseite erneut seine
                        Zugangsdaten eingeben, weil dies von der Internetseite und dem auf dem Computersystem des
                        Benutzers abgelegten Cookie übernommen wird. Ein weiteres Beispiel ist das Cookie eines
                        Warenkorbes im Online-Shop. Der Online-Shop merkt sich die Artikel, die ein Kunde in den
                        virtuellen Warenkorb gelegt hat, über ein Cookie.
                        <br />
                        <br />
                        Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite jederzeit mittels
                        einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung
                        von Cookies dauerhaft widersprechen. Ferner können bereits gesetzte Cookies jederzeit über einen
                        Internetbrowser oder andere Softwareprogramme gelöscht werden. Dies ist in allen gängigen
                        Internetbrowsern möglich. Deaktiviert die betroffene Person die Setzung von Cookies in dem
                        genutzten Internetbrowser, sind unter Umständen nicht alle Funktionen unserer Internetseite
                        vollumfänglich nutzbar.
                        <br />
                        <br />
                        Wir haben das Einwilligungs-Management-Tool „consentmanager“ (www.consentmanager.net) der
                        Jaohawi AB (Håltgelvågen 1b, 72348 Västerås, Schweden, info@consentmanager.net) auf unserer
                        Website eingebunden, um Einwilligungen für die Datenverarbeitung bzw. den Einsatz von Cookies
                        oder vergleichbaren Funktionen abzufragen. Sie haben mithilfe von „consentmanager“ die
                        Möglichkeit, Ihr Einverständnis für bestimmte Funktionalitäten unserer Website, z.B. zum Zwecke
                        der Einbindung externer Elemente, Einbindung von Streaming-Inhalten, statistischen Analyse,
                        Reichweitenmessung und personalisierten Werbung zu erteilen oder abzulehnen. Sie können mithilfe
                        von „consentmanager“ ihre Einwilligung für sämtliche Funktionen erteilen oder ablehnen oder ihre
                        Einwilligung für einzelne Zwecke oder einzelne Funktionen erteilen. Die von Ihnen vorgenommenen
                        Einstellungen können auch im Nachhinein von Ihnen geändert werden. Zweck der Einbindung von
                        „consentmanager“ ist es, den Nutzern unserer Website die Entscheidung über die vorgenannten
                        Dinge zu überlassen und im Rahmen der weiteren Nutzung unserer Website die Möglichkeit zu
                        bieten, bereits vorgenommene Einstellungen zu ändern. Im Zuge der Nutzung von „consentmanager“
                        werden personenbezogene Daten sowie Informationen der verwendeten Endgeräte wie die IP-Adresse
                        verarbeitet.
                        <br />
                        <br />
                        Rechtsgrundlage für die Verarbeitung ist entweder Art. 6 Abs. 1 S.1 lit. b) DSGVO
                        (Vertragserfüllung) bei den funktionalen Cookies und Art. 6 Abs. 1 S. 1 lit a) DSGVO
                        (Einwilligung) bei allen anderen. Die Verwendung des consentmanagers ist von unseren
                        überwiegenden berechtigten Interessen nach Art. 6 Abs. 1 S. 1 lit f) DSGVO gedeckt. Unsere
                        berechtigten Interessen an der Verarbeitung liegen in der Speicherung der Nutzereinstellungen
                        und Präferenzen in Bezug auf den Einsatz von Cookies und weiteren Funktionalitäten, da wir
                        aufgrund der Rechenschaftspflicht des Datenschutzrechtes dazu verpflichtet sind, diese
                        nachweisen zu können. „Consentmanager“ speichert Ihre Daten solange Ihre Nutzereinstellungen
                        aktiv sind. Zwei Jahre nach Vornahme der Nutzereinstellungen erfolgt eine erneute Abfrage der
                        Einwilligung. Die vorgenommenen Nutzereinstellungen werden dann erneut für diesen Zeitraum
                        gespeichert.
                        <br />
                        <br />
                    </p>
                    <p>
                        Sie können Widerspruch gegen die Verarbeitung einlegen. Ihr Widerspruchrecht besteht bei
                        Gründen, die sich aus Ihrer besonderen Situation ergeben. Zwecks Widerspruchs wenden Sie sich
                        bitte via E-Mail an <a href="mailto:info@consentmanager.net">info@consentmanager.net</a>.
                    </p>
                    <h2 className="title blue">a) Drittanbieter</h2>
                    <p></p>
                    <div id="cmpvendorlist"></div>
                    <p></p>
                    <h2 className="title blue">b) Cookie</h2>
                    <p></p>
                    <div id="cmpcookieinfo"></div>
                    <p></p>
                    <h2 className="title blue">5. Erfassung von allgemeinen Daten und Informationen</h2>
                    <p>
                        Die Internetseite der S-KON Gruppe erfasst mit jedem Aufruf der Internetseite durch eine
                        betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und
                        Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des Servers
                        gespeichert. Erfasst werden können die verwendeten Browsertypen und Versionen, das vom
                        zugreifenden System verwendete Betriebssystem, die Internetseite, von welcher ein zugreifendes
                        System auf unsere Internetseite gelangt (sogenannte Referrer), die Unterwebseiten, welche über
                        ein zugreifendes System auf unserer Internetseite angesteuert werden, das Datum und die Uhrzeit
                        eines Zugriffs auf die Internetseite, eine Internet-Protokoll-Adresse (IP-Adresse), der
                        Internet-Service-Provider des zugreifenden Systems und sonstige ähnliche Daten und
                        Informationen, die der Gefahrenabwehr im Falle von Angriffen auf unsere
                        informationstechnologischen Systeme dienen.
                        <br />
                        <br />
                        Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die S-KON Gruppe keine
                        Rückschlüsse auf die betroffene Person. Diese Informationen werden vielmehr benötigt, um die
                        Inhalte unserer Internetseite korrekt auszuliefern, die Inhalte unserer Internetseite sowie die
                        Werbung für diese zu optimieren, die dauerhafte Funktionsfähigkeit unserer
                        informationstechnologischen Systeme und der Technik unserer Internetseite zu gewährleisten sowie
                        um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen
                        Informationen bereitzustellen. Diese anonym erhobenen Daten und Informationen werden durch die
                        S-KON Gruppe daher einerseits statistisch und ferner mit dem Ziel ausgewertet, den Datenschutz
                        und die Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein optimales
                        Schutzniveau für die von uns verarbeiteten personenbezogenen Daten sicherzustellen. Die anonymen
                        Daten der Server-Logfiles werden getrennt von allen durch eine betroffene Person angegebenen
                        personenbezogenen Daten gespeichert.
                    </p>
                    <h2 className="title blue">6. Routinemäßige Löschung und Sperrung von personenbezogenen Daten</h2>
                    <p>
                        Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten der
                        betroffenen Person nur für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich
                        ist oder sofern dies durch den Europäischen Richtlinien- und Verordnungsgeber oder einen anderen
                        Gesetzgeber in Gesetzen oder Vorschriften, welchen der für die Verarbeitung Verantwortliche
                        unterliegt, vorgesehen wurde.
                        <br />
                        <br />
                        Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und
                        Verordnungsgeber oder einem anderen zuständigen Gesetzgeber vorgeschriebene Speicherfrist ab,
                        werden die personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen Vorschriften
                        gesperrt oder gelöscht.
                    </p>
                    <h2 className="title blue">7. Rechte der betroffenen Person</h2>
                    <p>
                        <h2 className="title blue">a) Recht auf Bestätigung</h2>
                    </p>
                    <p>
                        Jede betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber eingeräumte
                        Recht, von dem für die Verarbeitung Verantwortlichen eine Bestätigung darüber zu verlangen, ob
                        sie betreffende personenbezogene Daten verarbeitet werden. Möchte eine betroffene Person dieses
                        Bestätigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des
                        für die Verarbeitung Verantwortlichen wenden.
                    </p>
                    <h2 className="title blue">b) Recht auf Auskunft</h2>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                        Richtlinien- und Verordnungsgeber gewährte Recht, jederzeit von dem für die Verarbeitung
                        Verantwortlichen unentgeltliche Auskunft über die zu seiner Person gespeicherten
                        personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten. Ferner hat der Europäische
                        Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft über folgende Informationen
                        zugestanden:
                        <br />
                        <br />
                    </p>
                    <ul className="list">
                        <li>die Verarbeitungszwecke</li>
                        <li>die Kategorien personenbezogener Daten, die verarbeitet werden</li>
                        <li>
                            die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten
                            offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in
                            Drittländern oder bei internationalen Organisationen
                        </li>
                        <li>
                            falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden,
                            oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer
                        </li>
                        <li>
                            das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden
                            personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen
                            oder eines Widerspruchsrechts gegen diese Verarbeitung
                        </li>
                        <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
                        <li>
                            wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle
                            verfügbaren Informationen über die Herkunft der Daten
                        </li>
                        <li>
                            das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß
                            Artikel 22 Abs.1 und 4 DS-GVO und — zumindest in diesen Fällen — aussagekräftige
                            Informationen über die involvierte Logik sowie die Tragweite und die angestrebten
                            Auswirkungen einer derartigen Verarbeitung für die betroffene Person
                        </li>
                    </ul>
                    Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene Daten an ein
                    Drittland oder an eine internationale Organisation übermittelt wurden. Sofern dies der Fall ist, so
                    steht der betroffenen Person im Übrigen das Recht zu, Auskunft über die geeigneten Garantien im
                    Zusammenhang mit der Übermittlung zu erhalten.
                    <br />
                    <br />
                    Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu
                    jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                    <p></p>
                    <h2 className="title blue">c) Recht auf Berichtigung</h2>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                        Richtlinien- und Verordnungsgeber gewährte Recht, die unverzügliche Berichtigung sie
                        betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht der betroffenen
                        Person das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung
                        unvollständiger personenbezogener Daten — auch mittels einer ergänzenden Erklärung — zu
                        verlangen.
                        <br />
                        <br />
                        Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu
                        jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                    </p>
                    <h2 className="title blue">d) Recht auf Löschung (Recht auf Vergessen werden)</h2>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                        Richtlinien- und Verordnungsgeber gewährte Recht, von dem Verantwortlichen zu verlangen, dass
                        die sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der
                        folgenden Gründe zutrifft und soweit die Verarbeitung nicht erforderlich ist:
                        <br />
                        <br />
                    </p>
                    <ul className="list">
                        <li>
                            Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise
                            verarbeitet, für welche sie nicht mehr notwendig sind.
                        </li>
                        <li>
                            Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung gemäß Art.
                            6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO stützte, und es fehlt an
                            einer anderweitigen Rechtsgrundlage für die Verarbeitung.
                        </li>
                        <li>
                            Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch gegen die Verarbeitung
                            ein, und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die
                            betroffene Person legt gemäß Art. 21 Abs. 2 DS-GVO Widerspruch gegen die Verarbeitung ein.
                        </li>
                        <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
                        <li>
                            Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung
                            nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der
                            Verantwortliche unterliegt.
                        </li>
                        <li>
                            Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
                            Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.
                        </li>
                    </ul>
                    Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die Löschung von
                    personenbezogenen Daten, die bei der S-KON Gruppe gespeichert sind, veranlassen möchte, kann sie
                    sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der
                    Mitarbeiter der S-KON Gruppe wird veranlassen, dass dem Löschverlangen unverzüglich nachgekommen
                    wird.
                    <br />
                    <br />
                    Wurden die personenbezogenen Daten von der S-KON Gruppe öffentlich gemacht und ist unser Unternehmen
                    als Verantwortlicher gemäß Art. 17 Abs. 1 DS-GVO zur Löschung der personenbezogenen Daten
                    verpflichtet, so trifft die S-KON Gruppe unter Berücksichtigung der verfügbaren Technologie und der
                    Implementierungskosten angemessene Maßnahmen, auch technischer Art, um andere für die
                    Datenverarbeitung Verantwortliche, welche die veröffentlichten personenbezogenen Daten verarbeiten,
                    darüber in Kenntnis zu setzen, dass die betroffene Person von diesen anderen für die
                    Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links zu diesen personenbezogenen Daten
                    oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat, soweit die
                    Verarbeitung nicht erforderlich ist. Der Mitarbeiter der S-KON Gruppe wird im Einzelfall das
                    Notwendige veranlassen.
                    <p></p>
                    <h2 className="title blue">e) Recht auf Einschränkung der Verarbeitung</h2>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                        Richtlinien- und Verordnungsgeber gewährte Recht, von dem Verantwortlichen die Einschränkung der
                        Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
                    </p>
                    <ul className="list">
                        <li>
                            Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und
                            zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der
                            personenbezogenen Daten zu überprüfen.
                        </li>
                        <li>
                            Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der
                            personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der Nutzung der
                            personenbezogenen Daten.
                        </li>
                        <li>
                            Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung
                            nicht länger, die betroffene Person benötigt sie jedoch zur Geltendmachung, Ausübung oder
                            Verteidigung von Rechtsansprüchen.
                        </li>
                        <li>
                            Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DS-GVO
                            eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des Verantwortlichen
                            gegenüber denen der betroffenen Person überwiegen.
                        </li>
                    </ul>
                    Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene Person die
                    Einschränkung von personenbezogenen Daten, die bei der S-KON Gruppe gespeichert sind, verlangen
                    möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
                    Verantwortlichen wenden. Der Mitarbeiter der S-KON Gruppe wird die Einschränkung der Verarbeitung
                    veranlassen.
                    <p></p>
                    <h2 className="title blue">f) Recht auf Datenübertragbarkeit</h2>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                        Richtlinien- und Verordnungsgeber gewährte Recht, die sie betreffenden personenbezogenen Daten,
                        welche durch die betroffene Person einem Verantwortlichen bereitgestellt wurden, in einem
                        strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie hat außerdem das Recht,
                        diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die
                        personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der
                        Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder
                        auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe
                        automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer
                        Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher
                        Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.
                        <br />
                        <br />
                        Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß
                        Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem
                        Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch
                        machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt
                        werden.
                        <br />
                        <br />
                        Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die betroffene Person jederzeit
                        an einen Mitarbeiter der S-KON Gruppe wenden.
                    </p>
                    <h2 className="title blue">g) Recht auf Widerspruch</h2>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                        Richtlinien- und Verordnungsgeber gewährte Recht, aus Gründen, die sich aus ihrer besonderen
                        Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten,
                        die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO erfolgt, Widerspruch einzulegen. Dies
                        gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
                        <br />
                        <br />
                        Die S-KON Gruppe verarbeitet die personenbezogenen Daten im Falle des Widerspruchs nicht mehr,
                        es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den
                        Interessen, Rechten und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung
                        dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                        <br />
                        <br />
                        Verarbeitet die S-KON Gruppe personenbezogene Daten, um Direktwerbung zu betreiben, so hat die
                        betroffene Person das Recht, jederzeit Widerspruch gegen die Verarbeitung der personenbezogenen
                        Daten zum Zwecke derartiger Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit
                        solcher Direktwerbung in Verbindung steht. Widerspricht die betroffene Person gegenüber der
                        S-KON Gruppe der Verarbeitung für Zwecke der Direktwerbung, so wird die S-KON eKontor 24 GmbH
                        die personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.
                        <br />
                        <br />
                        Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer besonderen Situation
                        ergeben, gegen die sie betreffende Verarbeitung personenbezogener Daten, die bei der S-KON
                        eKontor 24 GmbH zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen
                        Zwecken gemäß Art. 89 Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche
                        Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich.
                        <br />
                        <br />
                        Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt jeden Mitarbeiter
                        der S-KON Gruppe oder einen anderen Mitarbeiter wenden. Der betroffenen Person steht es ferner
                        frei, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der
                        Richtlinie 2002/58/EG, ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei
                        denen technische Spezifikationen verwendet werden.
                    </p>
                    <h2 className="title blue">
                        h) Automatisierte Entscheidungen im Einzelfall einschließlich Profiling
                    </h2>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                        Richtlinien- und Verordnungsgeber gewährte Recht, nicht einer ausschließlich auf einer
                        automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu
                        werden, die ihr gegenüber rechtliche Wirkung entfaltet oder sie in ähnlicher Weise erheblich
                        beeinträchtigt, sofern die Entscheidung (1) nicht für den Abschluss oder die Erfüllung eines
                        Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich ist, oder (2)
                        aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche
                        unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung der
                        Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen Person enthalten oder
                        (3) mit ausdrücklicher Einwilligung der betroffenen Person erfolgt.
                        <br />
                        <br />
                        Ist die Entscheidung (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen der
                        betroffenen Person und dem Verantwortlichen erforderlich oder (2) erfolgt sie mit ausdrücklicher
                        Einwilligung der betroffenen Person, trifft die S-KON Gruppe angemessene Maßnahmen, um die
                        Rechte und Freiheiten sowie die berechtigten Interessen der betroffenen Person zu wahren, wozu
                        mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen,
                        auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
                        <br />
                        <br />
                        Möchte die betroffene Person Rechte mit Bezug auf automatisierte Entscheidungen geltend machen,
                        kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen
                        wenden.
                    </p>
                    <h2 className="title blue">i) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</h2>
                    <p>
                        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen
                        Richtlinien- und Verordnungsgeber gewährte Recht, eine Einwilligung zur Verarbeitung
                        personenbezogener Daten jederzeit zu widerrufen.
                        <br />
                        <br />
                        Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend machen, kann sie
                        sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                    </p>
                    <p></p>
                    <h2 className="title blue">
                        8. Datenschutzbestimmungen zu Einsatz und Verwendung von Google Analytics
                    </h2>
                    <p>
                        Der für die Verarbeitung Verantwortliche hat auf dieser Internetseite die Komponente Google
                        Analytics (mit Anonymisierungsfunktion) integriert. Google Analytics ist ein Web-Analyse-Dienst.
                        Web-Analyse ist die Erhebung, Sammlung und Auswertung von Daten über das Verhalten von Besuchern
                        von Internetseiten. Ein Web-Analyse-Dienst erfasst unter anderem Daten darüber, von welcher
                        Internetseite eine betroffene Person auf eine Internetseite gekommen ist (sogenannte Referrer),
                        auf welche Unterseiten der Internetseite zugegriffen oder wie oft und für welche Verweildauer
                        eine Unterseite betrachtet wurde. Eine Web-Analyse wird überwiegend zur Optimierung einer
                        Internetseite und zur Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.
                        <br />
                        <br />
                        Betreibergesellschaft der Google-Analytics-Komponente ist die Google Inc., 1600 Amphitheatre
                        Pkwy, Mountain View, CA 94043-1351, USA.
                        <br />
                        <br />
                        Der für die Verarbeitung Verantwortliche verwendet für die Web-Analyse über Google Analytics den
                        Zusatz "_gat._anonymizeIp". Mittels dieses Zusatzes wird die IP-Adresse des Internetanschlusses
                        der betroffenen Person von Google gekürzt und anonymisiert, wenn der Zugriff auf unsere
                        Internetseiten aus einem Mitgliedstaaten der Europäischen Union oder aus einem anderen
                        Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum erfolgt.
                        <br />
                        <br />
                        Der Zweck der Google-Analytics-Komponente ist die Analyse der Besucherströme auf unserer
                        Internetseite. Google nutzt die gewonnenen Daten und Informationen unter anderem dazu, die
                        Nutzung unserer Internetseite auszuwerten, um für uns Online-Reports, welche die Aktivitäten auf
                        unseren Internetseiten aufzeigen, zusammenzustellen, und um weitere mit der Nutzung unserer
                        Internetseite in Verbindung stehende Dienstleistungen zu erbringen.
                        <br />
                        <br />
                        Google Analytics setzt ein Cookie auf dem informationstechnologischen System der betroffenen
                        Person. Was Cookies sind, wurde oben bereits erläutert. Mit Setzung des Cookies wird Google eine
                        Analyse der Benutzung unserer Internetseite ermöglicht. Durch jeden Aufruf einer der
                        Einzelseiten dieser Internetseite, die durch den für die Verarbeitung Verantwortlichen betrieben
                        wird und auf welcher eine Google-Analytics-Komponente integriert wurde, wird der Internetbrowser
                        auf dem informationstechnologischen System der betroffenen Person automatisch durch die
                        jeweilige Google-Analytics-Komponente veranlasst, Daten zum Zwecke der Online-Analyse an Google
                        zu übermitteln. Im Rahmen dieses technischen Verfahrens erhält Google Kenntnis über
                        personenbezogene Daten, wie der IP-Adresse der betroffenen Person, die Google unter anderem dazu
                        dienen, die Herkunft der Besucher und Klicks nachzuvollziehen und in der Folge
                        Provisionsabrechnungen zu ermöglichen.
                        <br />
                        <br />
                        Mittels des Cookies werden personenbezogene Informationen, beispielsweise die Zugriffszeit, der
                        Ort, von welchem ein Zugriff ausging und die Häufigkeit der Besuche unserer Internetseite durch
                        die betroffene Person, gespeichert. Bei jedem Besuch unserer Internetseiten werden diese
                        personenbezogenen Daten, einschließlich der IP-Adresse des von der betroffenen Person genutzten
                        Internetanschlusses, an Google in den Vereinigten Staaten von Amerika übertragen. Diese
                        personenbezogenen Daten werden durch Google in den Vereinigten Staaten von Amerika gespeichert.
                        Google gibt diese über das technische Verfahren erhobenen personenbezogenen Daten unter
                        Umständen an Dritte weiter.
                        <br />
                        <br />
                        Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite, wie oben bereits
                        dargestellt, jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers
                        verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche Einstellung
                        des genutzten Internetbrowsers würde auch verhindern, dass Google ein Cookie auf dem
                        informationstechnologischen System der betroffenen Person setzt. Zudem kann ein von Google
                        Analytics bereits gesetzter Cookie jederzeit über den Internetbrowser oder andere
                        Softwareprogramme gelöscht werden.
                        <br />
                        <br />
                        Ferner besteht für die betroffene Person die Möglichkeit, einer Erfassung der durch Google
                        Analytics erzeugten, auf eine Nutzung dieser Internetseite bezogenen Daten sowie der
                        Verarbeitung dieser Daten durch Google zu widersprechen und eine solche zu verhindern. Hierzu
                        muss die betroffene Person ein Browser-Add-On unter dem Link
                        https://tools.google.com/dlpage/gaoptout herunterladen und installieren. Dieses Browser-Add-On
                        teilt Google Analytics über JavaScript mit, dass keine Daten und Informationen zu den Besuchen
                        von Internetseiten an Google Analytics übermittelt werden dürfen. Die Installation des
                        Browser-Add-Ons wird von Google als Widerspruch gewertet. Wird das informationstechnologische
                        System der betroffenen Person zu einem späteren Zeitpunkt gelöscht, formatiert oder neu
                        installiert, muss durch die betroffene Person eine erneute Installation des Browser-Add-Ons
                        erfolgen, um Google Analytics zu deaktivieren. Sofern das Browser-Add-On durch die betroffene
                        Person oder einer anderen Person, die ihrem Machtbereich zuzurechnen ist, deinstalliert oder
                        deaktiviert wird, besteht die Möglichkeit der Neuinstallation oder der erneuten Aktivierung des
                        Browser-Add-Ons.
                        <br />
                        <br />
                        Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter{' '}
                        <a href="https://www.google.de/intl/de/policies/privacy/">
                            https://www.google.de/intl/de/policies/privacy/
                        </a>{' '}
                        und unter{' '}
                        <a href="http://www.google.com/analytics/terms/de.html">
                            http://www.google.com/analytics/terms/de.html
                        </a>{' '}
                        abgerufen werden. Google Analytics wird unter diesem Link{' '}
                        <a href="https://www.google.com/intl/de_de/analytics/">
                            https://www.google.com/intl/de_de/analytics/
                        </a>{' '}
                        genauer erläutert.
                    </p>
                    <h2 className="title blue">9. Rechtsgrundlage der Verarbeitung</h2>
                    <p>
                        Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge,
                        bei denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen. Ist die
                        Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei die
                        betroffene Person ist, erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall
                        ist, die für eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder
                        Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I lit. b DS-GVO. Gleiches
                        gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen
                        erforderlich sind, etwa in Fällen von Anfragen zur unseren Produkten oder Leistungen. Unterliegt
                        unser Unternehmen einer rechtlichen Verpflichtung durch welche eine Verarbeitung von
                        personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher
                        Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c DS-GVO. In seltenen Fällen könnte die
                        Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der
                        betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre beispielsweise
                        der Fall, wenn ein Besucher in unserem Betrieb verletzt werden würde und daraufhin sein Name,
                        sein Alter, seine Krankenkassendaten oder sonstige lebenswichtige Informationen an einen Arzt,
                        ein Krankenhaus oder sonstige Dritte weitergegeben werden müssten. Dann würde die Verarbeitung
                        auf Art. 6 I lit. d DS-GVO beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f
                        DS-GVO beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der
                        vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung eines
                        berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist, sofern die
                        Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen. Solche
                        Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen
                        Gesetzgeber besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes
                        Interesse anzunehmen sein könnte, wenn die betroffene Person ein Kunde des Verantwortlichen ist
                        (Erwägungsgrund 47 Satz 2 DS-GVO).
                    </p>
                    <h2 className="title blue">10. Dauer, für die die personenbezogenen Daten gespeichert werden</h2>
                    <p>
                        Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige
                        gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten
                        routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung
                        erforderlich sind.
                    </p>
                    <h2 className="title blue">
                        11. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der personenbezogenen Daten;
                        Erforderlichkeit für den Vertragsabschluss; Verpflichtung der betroffenen Person, die
                        personenbezogenen Daten bereitzustellen; mögliche Folgen der Nichtbereitstellung
                    </h2>
                    <p>
                        Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum Teil gesetzlich
                        vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus vertraglichen Regelungen (z.B.
                        Angaben zum Vertragspartner) ergeben kann. Mitunter kann es zu einem Vertragsschluss
                        erforderlich sein, dass eine betroffene Person uns personenbezogene Daten zur Verfügung stellt,
                        die in der Folge durch uns verarbeitet werden müssen. Die betroffene Person ist beispielsweise
                        verpflichtet uns personenbezogene Daten bereitzustellen, wenn unser Unternehmen mit ihr einen
                        Vertrag abschließt. Eine Nichtbereitstellung der personenbezogenen Daten hätte zur Folge, dass
                        der Vertrag mit dem Betroffenen nicht geschlossen werden könnte. Vor einer Bereitstellung
                        personenbezogener Daten durch den Betroffenen muss sich der Betroffene an einen unserer
                        Mitarbeiter wenden. Unser Mitarbeiter klärt den Betroffenen einzelfallbezogen darüber auf, ob
                        die Bereitstellung der personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder
                        für den Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, die personenbezogenen
                        Daten bereitzustellen, und welche Folgen die Nichtbereitstellung der personenbezogenen Daten
                        hätte.
                    </p>
                    <h2 className="title blue">12. Bestehen einer automatisierten Entscheidungsfindung</h2>
                    <p>
                        Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische
                        Entscheidungsfindung oder ein Profiling.
                    </p>
                    <h2 className="title blue">13. Änderungen unserer Datenschutzerklärung</h2>
                    <p>
                        S-KON kann diese Mitteilung von Zeit zu Zeit ändern. Solche Änderungen werden hier
                        veröffentlicht und Ihnen gegebenenfalls schriftlich mitgeteilt. Wir empfehlen Ihnen, sich
                        regelmäßig über Updates und Änderungen zu informieren.
                    </p>
                </ContentBox>
            </PageLayout>
        </div>
    )
}

export default PrivacyPage
