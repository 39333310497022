// Validations
import { formatDate, normalizeDateFormat } from './formatDate'

export const validations = {
    required: (value) => (value ? undefined : 'Dieses Feld ist ein Pflichtfeld.'),
    phoneNumber: (value) =>
        value && !/^\+[1-9][0-9][0-9]{1,12}$/i.test(value)
            ? 'Bitte gib deine Telefonnummer mit Ländervorwahl ein, Beispiel: +49176000000'
            : undefined,
    maxLength: (max) => (value) => value && value.length > max ? `Maximal ${max} Zeichen erlaubt` : undefined,
    minLength: (min) => (value) => value && value.length < min ? `Mindestens ${min} Zeichen erforderlich` : undefined,
    minDate: (date: Date) => (value) =>
        parseDateToTime(value) < date.getTime() ? `Datum muss nach dem ${formatDate(date)} liegen` : undefined,
    maxDate: (date: Date) => (value) =>
        parseDateToTime(value) >= date.getTime() ? `Datum muss vor dem ${formatDate(date)} liegen` : undefined,
    validDate: (value) =>
        !value?.trim().match(/^[0-3]?[0-9][/.][0-3]?[0-9][/.](?:[0-9]{2})?[0-9]{2}$/)
            ? `Es muss ein gültiges Datum angegeben werden`
            : undefined,
    minAge: (age) => (value) =>
        !checkAge(age, new Date(normalizeDateFormat(value))) ? `Du musst mindestens ${age} Jahre alt sein.` : undefined,
    email: (value) =>
        value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value) ? 'E-Mail Adresse ist ungültig' : undefined,
    alpha: (value) =>
        value && !/^[a-zäöüß&.,+ -]*$/i.test(value)
            ? 'Ungültige Zeichen, es sind nur A-Z, Umlaute und . , + - & zulässig.'
            : undefined,
    alphaNumeric: (value) =>
        value && !/^[a-z0-9äöüß&.,+ -]*$/i.test(value)
            ? 'Ungültige Zeichen, es sind nur A-Z, 0-9, Umlaute und . , + - & zulässig.'
            : undefined,
    alphaNumericMustHaveNumber: (value) => {
        if (!value) return

        if (!/[^a-zA-Z&$%&#<>*'°^=/;.,+ -]/i.test(value)) {
            return 'Bitte prüfe die Hausnummer.'
        }

        if (!/^[a-z0-9äöüß&.,+ -]*$/i.test(value)) {
            return undefined
        }

        return undefined
    },
    numeric: (value) =>
        value && !/^[0-9.,]*$/i.test(value) ? 'Ungültige Zeichen, es sind nur Zahlen zulässig.' : undefined,
    numericNoLeadingZero: (value) =>
        value && !/^[1-9][0-9.,]*$/i.test(value) ? 'Die Telefonnummer darf nicht mit einer Null beginnen' : undefined,
    iban: (value) =>
        value && !/^DE(?:[ ]?[0-9]){20}$/i.test(value)
            ? 'Es muss eine gültige IBAN (Deutschland) eingegeben werden.'
            : undefined,
}

export const validateInputs = (
    validateFields: { [index: string]: Function[] },
    data: any,
    prefix: string = ''
): { [index: string]: string } => {
    const errorList = {}
    Object.keys(validateFields).forEach((fieldName) => {
        validateFields[fieldName].forEach((validation) => {
            const error = validation(data[fieldName])
            if (error && !errorList[prefix + fieldName]) {
                errorList[prefix + fieldName] = error
            }
        })
    })
    return errorList
}

export const parseDateToTime = (value: string): number => {
    return Date.parse(normalizeDateFormat(value))
}

const checkAge = (age, compDate) => {
    const now = new Date()
    if (compDate.getFullYear() < now.getFullYear() - age) {
        return true
    } else if (compDate.getFullYear() > now.getFullYear() - age) {
        return false
    } else {
        if (compDate.getMonth() > now.getMonth()) {
            return true
        } else if (compDate.getMonth() < now.getMonth()) {
            return false
        } else {
            if (compDate.getDate() <= now.getDate()) {
                return true
            } else {
                return false
            }
        }
    }
}
