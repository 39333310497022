import React, { ReactNode } from 'react'
import classNames from 'classnames'
import './Card.scss'

interface Props {
    className?: string
    children?: ReactNode
}

const Card = (props: Props) => {
    return (
        <div className={classNames('card', props.className)}>
            {props.children}
        </div>
    )
}

export default Card
