import React from 'react'
import logo from '../../../assets/logo.svg'
import './Logo.scss'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import Routes from '../../../config/routes'

interface Props {
    className?: string
}

const Logo = (props: Props) => {
    const navigate = useNavigate()

    return (
        <img
            alt="harvesting-app Logo"
            className={classNames('logo', props.className)}
            src={logo}
            onClick={() => navigate(Routes.HOME_PAGE)}
        />
    )
}

export default Logo
