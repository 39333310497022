import { INotificationState } from '../../types/INotificationState'
import { actionIds, BaseAction } from '../common'

const defaultState: INotificationState = {
    notifications: [],
}

const notificationReducer = (
    state: INotificationState = defaultState,
    action: BaseAction
): INotificationState => {
    switch (action.type) {
        case actionIds.SET_NOTIFICATION:
            if (!action.payload.created) {
                action.payload.created = String(new Date())
            }
            if (state.notifications.length === 5) {
                state.notifications.shift()
            }

            return {
                ...defaultState,
                notifications: [...state.notifications, action.payload]
            }

        case actionIds.UNSET_NOTIFICATION:
            return {
                ...defaultState,
                notifications: state.notifications.filter(notification => {
                    return action.payload.created !== notification.created || action.payload.message !== notification.message
                })
            }

        case actionIds.CLEAR_NOTIFICATIONS:
            return {
                notifications: []
            }

        default:
            return state
    }
}

export default notificationReducer
