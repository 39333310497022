import React, { useEffect } from 'react'
import './RegistrationStep6.scss'
import PaperPlane from '../../../assets/paperplane.svg'
import resetStoreAction from '../../../state/actions/resetStore'
import { useDispatch } from 'react-redux'

interface Props {}

const RegistrationStep6 = (props: Props) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetStoreAction())
    }, [dispatch])

    return (
        <div className="registration-step-6__thank-you-overlay">
            <div className="registration-step-6__thank-you-overlay__content">
                <img src={PaperPlane} alt="Papierflieger" className="registration-step-6__thank-you-overlay__icon" />
                <div className="registration-step-6__thank-you-overlay__headline">
                    Super, du hast deine Registrierung abgeschlossen!
                </div>
                <div className="registration-step-6__thank-you-overlay__subline">
                    Bitte beachte: Es kann bis zu fünf Werktage dauern, bis wir deine Registrierung bearbeitet haben und
                    uns bei dir zurückmelden.
                    <br />
                    In Kürze erhältst du eine E-Mail von uns zur Bestätigung.
                </div>
            </div>
        </div>
    )
}

export default RegistrationStep6
