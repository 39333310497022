import React, { useEffect, useState } from 'react'
import FormInput from '../../atoms/FormInput/FormInput'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import './RegistrationStep3.scss'
import { validateInputs, validations } from '../../../utils/validations'
import setFormDataAction from '../../../state/actions/setFormData'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../../types/IRootState'
import { IAddress } from '../../../types/IAddress'
import FormAutocomplete from '../../atoms/FormAutocomplete/FormAutocomplete'
import setCountriesAction from '../../../state/actions/setCountries'
import setRegistrationStepAction from '../../../state/actions/setRegistrationStep'
import SecondaryButton from '../../atoms/SecondaryButton/SecondaryButton'

interface Props {
    onSubmit: () => void
}

const emptyAddresses = {
    office: {
        address: '',
        address_number: '',
        address_extra: '',
        address_type: 'office',
        zip: '',
        city: '',
        country_id: 0,
    },
    billing: {
        address: '',
        address_number: '',
        address_extra: '',
        address_type: 'billing',
        zip: '',
        city: '',
        country_id: 0,
    },
}

const RegistrationStep3 = (props: Props) => {
    const [errors, setErrors] = useState<any>({})
    const formData = useSelector((state: IRootState) => state.form.formData)
    const countries = useSelector((state: IRootState) => state.form.countries)
    const registrationStep = useSelector((state: IRootState) => state.form.registrationStep) as number
    const [addressData, setAddressData] = useState<{
        [index: string]: IAddress
    }>({
        office: {
            ...emptyAddresses.office,
            ...formData.addresses?.find((address) => address.address_type === 'office'),
        },
        billing: {
            ...emptyAddresses.billing,
            ...formData.addresses?.find((address) => address.address_type === 'billing'),
        },
    })
    const [submitted, setSubmitted] = useState<boolean>(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (countries.length === 0) {
            let abortController = new AbortController()
            const dataFetch = async () => {
                const data = await (
                    await fetch('/api/v1/frontend/countries', {
                        signal: abortController.signal,
                    })
                ).json()

                dispatch(setCountriesAction(data.countries))
            }

            dataFetch()
            return () => {
                abortController.abort()
            }
        }
    }, [dispatch, countries])

    const formChange = (type: 'office' | 'billing', data: any) => {
        setAddressData({
            ...addressData,
            [type]: { ...addressData[type], ...data },
        })
    }

    const copyAddress = () => {
        Object.keys(addressData['billing']).forEach((key) => {
            if (key === 'country_id') {
                addressData['office'][key] = addressData['billing'][key]
            } else if (key !== 'address_type') {
                addressData['office'][key] = `${addressData['billing'][key]}`
            }
        })
        setAddressData({ ...addressData })
    }

    const submit = () => {
        setSubmitted(true)

        const validateFields = {
            office: {
                address: [validations.required],
                address_number: [validations.required],
                zip: [validations.required],
                city: [validations.required],
                country_id: [validations.required],
            },
            billing: {
                address: [validations.required],
                address_number: [validations.required],
                zip: [validations.required],
                city: [validations.required],
                country_id: [validations.required],
            },
        }

        const errorList = {
            ...validateInputs(validateFields.office, addressData['office'], 'office_'),
            ...validateInputs(validateFields.billing, addressData['billing'], 'billing_'),
        }
        setErrors(errorList)
        console.log('addressData', addressData)
        console.log('errorList', errorList)

        if (Object.keys(errorList).length === 0) {
            // submit changes to form
            let addresses: IAddress[] = []
            if (addressData['office'].address !== '') {
                addresses.push(addressData['office'])
            }
            if (addressData['billing'].address !== '') {
                addresses.push(addressData['billing'])
            }
            dispatch(
                setFormDataAction({
                    ...formData,
                    addresses: addresses,
                })
            )

            // success
            props.onSubmit()
        }
    }

    return (
        <div className="registration-step-3 registration-step-3--col-span-2">
            <div className="registration-step-3__headline">Rechnungsanschrift</div>
            <div className="registration-step-3__container">
                <FormInput
                    name="billing_address"
                    label="Straße*"
                    error={errors['billing_address']}
                    valid={submitted && !errors['billing_address']}
                    value={addressData['billing'].address}
                    onChange={(evt) => formChange('billing', { address: evt.target.value })}
                />
                <div className="registration-step-3__split-inputs">
                    <FormInput
                        name="billing_address_number"
                        label="Hausnummer"
                        error={errors['billing_address_number']}
                        valid={submitted && !errors['billing_address_number']}
                        value={addressData['billing'].address_number}
                        onChange={(evt) =>
                            formChange('billing', {
                                address_number: evt.target.value,
                            })
                        }
                    />
                    <FormInput
                        name="billing_address_extra"
                        label="Adresszusatz"
                        error={errors['billing_address_extra']}
                        valid={submitted && !errors['billing_address_extra']}
                        value={addressData['billing'].address_extra}
                        onChange={(evt) =>
                            formChange('billing', {
                                address_extra: evt.target.value,
                            })
                        }
                    />
                </div>
                <div className="registration-step-3__split-inputs">
                    <FormInput
                        name="billing_zip"
                        label="PLZ"
                        error={errors['billing_zip']}
                        valid={submitted && !errors['billing_zip']}
                        value={addressData['billing'].zip}
                        onChange={(evt) => formChange('billing', { zip: evt.target.value })}
                    />
                    <FormInput
                        name="billing_city"
                        label="Ort"
                        error={errors['billing_city']}
                        valid={submitted && !errors['billing_city']}
                        value={addressData['billing'].city}
                        onChange={(evt) => formChange('billing', { city: evt.target.value })}
                    />
                </div>

                <FormAutocomplete
                    name="billing_country_id"
                    label="Land"
                    error={errors['billing_country_id']}
                    valid={submitted && !errors['billing_country_id']}
                    value={countries.find((country) => country.id === addressData['billing'].country_id.toString())}
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    onChange={(evt, newValue) =>
                        formChange('billing', {
                            country_id: parseInt(newValue.id),
                        })
                    }
                />
            </div>

            <div className="registration-step-3__headline">
                Arbeitsanschrift{' '}
                <span className="registration-step-3__copy-address" onClick={() => copyAddress()}>
                    von Rechnungsadresse übernehmen
                </span>
            </div>
            <div className="registration-step-3__container">
                <FormInput
                    name="office_address"
                    label="Straße*"
                    error={errors['office_address']}
                    valid={submitted && !errors['office_address']}
                    value={addressData['office'].address}
                    onChange={(evt) => formChange('office', { address: evt.target.value })}
                />
                <div className="registration-step-3__split-inputs">
                    <FormInput
                        name="office_address_number"
                        label="Hausnummer"
                        error={errors['office_address_number']}
                        valid={submitted && !errors['office_address_number']}
                        value={addressData['office'].address_number}
                        onChange={(evt) =>
                            formChange('office', {
                                address_number: evt.target.value,
                            })
                        }
                    />
                    <FormInput
                        name="office_address_extra"
                        label="Adresszusatz"
                        error={errors['office_address_extra']}
                        valid={submitted && !errors['office_address_extra']}
                        value={addressData['office'].address_extra}
                        onChange={(evt) =>
                            formChange('office', {
                                address_extra: evt.target.value,
                            })
                        }
                    />
                </div>
                <div className="registration-step-3__split-inputs">
                    <FormInput
                        name="office_zip"
                        label="PLZ"
                        error={errors['office_zip']}
                        valid={submitted && !errors['office_zip']}
                        value={addressData['office'].zip}
                        onChange={(evt) => formChange('office', { zip: evt.target.value })}
                    />
                    <FormInput
                        name="office_city"
                        label="Ort"
                        error={errors['office_city']}
                        valid={submitted && !errors['office_city']}
                        value={addressData['office'].city}
                        onChange={(evt) => formChange('office', { city: evt.target.value })}
                    />
                </div>

                <FormAutocomplete
                    name="office_country_id"
                    label="Land"
                    error={errors['office_country_id']}
                    valid={submitted && !errors['office_country_id']}
                    value={
                        countries.find(
                            (country) => String(country.id) === addressData['office'].country_id.toString()
                        ) || null
                    }
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    onChange={(evt, newValue) =>
                        formChange('office', {
                            country_id: parseInt(newValue.id),
                        })
                    }
                />
            </div>
            <div className="registration-step-3__button-container">
                <SecondaryButton onClick={() => dispatch(setRegistrationStepAction(registrationStep - 1))}>
                    Zurück
                </SecondaryButton>
                <PrimaryButton onClick={() => submit()}>Weiter</PrimaryButton>
            </div>
        </div>
    )
}

export default RegistrationStep3
