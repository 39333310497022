import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import './ScrollButton.scss'


interface Props {
    className?: string
}

const ScrollButton = (props: Props) => {
    const [isVisible, setIsVisible] = useState<boolean>(false)

    const toggleVivibility = () => {
        if (window.pageYOffset > 480) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVivibility)

        return () => {
            window.removeEventListener('scroll', toggleVivibility)

        }
    }, [isVisible, setIsVisible])


    return (
        <div className={classNames(`scroll-button__container ${isVisible}`, props.className)} onClick={scrollToTop}>
            <div className='scroll-button__icon' />
        </div>
    )
}

export default ScrollButton