import React, { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
// import {StatusPage} from "./components/pages/StatusPage";
import HomePage from './components/pages/HomePage'
import { getRoutes } from './config/getRoutes'
import { removeLngPrefix } from './config/i18next'
import routes from './config/routes'
import RegistrationPage from './components/pages/RegistrationPage'
import PrivacyPage from './components/pages/PrivacyPage'
import TermsPage from './components/pages/TermsPage'
import ImprintPage from './components/pages/ImprintPage'
import Notifications from './components/molecules/Notifications/Notifications'
import RegistrationSuccessPage from './components/pages/RegistrationSuccessPage'
import { HelmetProvider } from 'react-helmet-async'

interface Props {
    history: any
}

const RouterWrapper = (props: Props) => {
    // const { history } = props;
    const {
        i18n,
        i18n: { language },
    } = useTranslation()

    // Redirect user to '/basename' if user lands on '/'
    if (!window.location.pathname.includes(language)) {
        window.history.replaceState('', '', language + window.location.pathname)
    }

    useLayoutEffect(() => {
        const currentPathname = window.location.href.replace(
            window.location.origin,
            ''
        )
        const newPathname = `/${language}${removeLngPrefix(currentPathname)}`

        if (currentPathname !== newPathname) {
            window.history.replaceState({}, '', newPathname)
        }
    }, [language, i18n])

    return (
        <BrowserRouter basename={`/${language}`}>
            <HelmetProvider>
                <Notifications />
                <Routes>
                    <>
                        <Route
                            path={getRoutes(language, routes.HOME_PAGE)}
                            element={<HomePage />}
                        />
                        <Route
                            path={getRoutes(language, routes.REGISTRATION_PAGE)}
                            element={<RegistrationPage />}
                        />
                        <Route
                            path={getRoutes(
                                language,
                                routes.REGISTRATION_SUCCESS_PAGE
                            )}
                            element={<RegistrationSuccessPage />}
                        />
                        <Route
                            path={getRoutes(language, routes.PRIVACY_PAGE)}
                            element={<PrivacyPage />}
                        />
                        <Route
                            path={getRoutes(language, routes.IMPRINT_PAGE)}
                            element={<ImprintPage />}
                        />
                        <Route
                            path={getRoutes(language, routes.TERMS_PAGE)}
                            element={<TermsPage />}
                        />

                        <Route
                            path="/"
                            element={<Navigate to={routes.HOME_PAGE} replace />}
                        />
                    </>
                </Routes>
            </HelmetProvider>
        </BrowserRouter>
    )
}

export default RouterWrapper
