import React, { ReactNode } from 'react'
import { Dialog, DialogContent, IconButton, Slide, useMediaQuery } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { ReactComponent as Times2Icon } from '../../../assets/times2.svg'
import FloatingButton from '../FloatingButton/FloatingButton'
import './Overlay.scss'

interface Props {
    open: boolean
    onClick?: (e: React.MouseEvent) => void
    children?: ReactNode
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const Overlay = (props: Props) => {
    const fullScreen = useMediaQuery('(max-width: 992px)')
    return (
        <Dialog open={props.open} TransitionComponent={Transition} onClose={props.onClick} fullScreen={fullScreen}>
            <IconButton
                aria-label="close"
                onClick={props.onClick}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <Times2Icon className="overlay__close-icon" />
            </IconButton>
            <DialogContent className="overlay__content">{props.children}</DialogContent>
            <div>
                <FloatingButton buttonName="Werde jetzt S-KON Expert" onClick={props.onClick} />
            </div>
        </Dialog>
    )
}

export default Overlay
