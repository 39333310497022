import React, { useCallback, useEffect, useRef } from 'react'
import './Notification.scss'
import INotification from '../../../types/INotification'
import { Slide } from '@mui/material'
import { useDispatch } from 'react-redux'
import unsetNotification from '../../../state/actions/unsetNotification'
import { ReactComponent as Times2Icon } from '../../../assets/times2.svg'

interface Props {
    notification: INotification
}

const Notification = (props: Props) => {
    const dispatch = useDispatch()
    const timeout = 3000
    let startTimerInterval = useRef<NodeJS.Timeout>()

    const timeChecker = useCallback(() => {
        startTimerInterval.current = setTimeout(() => {
            dispatch(unsetNotification(props.notification))
        }, timeout)

    }, [dispatch, props.notification, timeout])

    useEffect(() => {
        timeChecker()

        return () => {
            clearTimeout(startTimerInterval.current as NodeJS.Timeout)
        }
    }, [timeChecker])

    const closeNotification = (notification: INotification) => {
        clearTimeout(startTimerInterval.current as NodeJS.Timeout)
        dispatch(unsetNotification(notification))
    }

    return (
        <Slide direction='left' in={Boolean(props.notification.created)} mountOnEnter unmountOnExit>
            <div className='notification' role='alert' aria-live='assertive'>
                <i aria-hidden='true' className={`notification__bubble notification__bubble--${props.notification.type}`}></i>
                <p>
                    {props.notification.message}
                </p>
                <button type='button' className='notification__closer' aria-label='Nachricht schließen' onClick={() => closeNotification(props.notification)}>
                    <Times2Icon className='notification__close-icon' />
                </button>
            </div>
        </Slide>
    )
}

export default Notification
