import React, { SyntheticEvent } from 'react'
import classNames from 'classnames'
import { Autocomplete, Paper, TextField } from '@mui/material'
import { ReactComponent as ChevrondownIcon } from '../../../assets/chevrondown.svg'
import './FormAutocomplete.scss'

interface Props {
    id?: string
    name? : string
    className?: string
    label?: string | null | undefined
    helper?: string
    error?: string
    options: any[]
    getOptionLabel?: (option: any) => string
    valid?: boolean
    type?: string
    onChange?: (evt: SyntheticEvent<Element>, value: any) => void
    value?: any
    multiple?: boolean
}
const DropdownPaper = (props) => (
    <Paper
        {...props}
        variant='outlined'
        square
        sx={{
            '& .MuiAutocomplete-noOptions': {
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                margin: 0,
                padding: '0 0 0 12px',
            },
        }}
        style={{
            border: '1px solid #d9dada',
            marginTop: '5px',
        }}
    />
)

const FormAutocomplete = (props: Props) => {
    return (
        <div className={classNames('form-autocomplete', props.className)}>
            {props.label && <p className="form-autocomplete__label">{props.label}</p>}
            <Autocomplete
                options={props.options}
                getOptionLabel={props.getOptionLabel}
                onChange={props.onChange}
                value={props.value}
                fullWidth
                isOptionEqualToValue={(option, value) => option.iso2 === value.iso2}
                multiple={props.multiple}
                PaperComponent={DropdownPaper}
                disableClearable={props.value !== null}
                popupIcon={<ChevrondownIcon className="form-autocomplete__chevrondown-icon" style={{ backgroundColor: 'transparent' }} />}
                noOptionsText='No options'
                sx={{
                    '& .MuiAutocomplete-popupIndicator': {
                        marginRight: '12px',
                    },
                }}
                renderInput={params =>
                    <TextField
                        {...params}
                        error={Boolean(props.error)}
                        variant="standard"
                        sx={{
                            '& .Mui-focused': {
                                borderColor: '#64b4ff',
                            },
                            '& .Mui-error': {
                                borderColor: '#f04678',
                            },
                            '& .MuiFormHelperText-root': {
                                paddingTop: '5px',
                                fontSize: '10px',
                                color: '#21333a',
                                '&.Mui-error': {
                                    color: '#f04678',
                                },
                            },
                            '& .MuiFormLabel-root': {
                                '&.Mui-error': {
                                    color: '#f04678',
                                },
                            },
                        }}
                        InputProps={{
                            ...params.InputProps,
                            style: {
                                // caretColor: '#64b4ff',
                                padding: '0px 12px 0 12px',
                            },
                            className: classNames(
                                'form-autocomplete__textfield',
                                props.valid && 'form-autocomplete__textfield-success'
                            ),
                            disableUnderline: true,
                            endAdornment: (
                                <>
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            shrink: true,
                        }}
                        helperText={
                            props.error ? (
                                <>{props.error}</>
                            ) : props.helper ? (
                                <>{props.helper}</>
                            ) : (
                                '\u00a0'
                            )
                        }
                    />}
            />
        </div>
    )
}

export default FormAutocomplete