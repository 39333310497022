const routes = {
    HOME_PAGE: '/',
    REGISTRATION_PAGE: '/registrierung',
    REGISTRATION_SUCCESS_PAGE: '/registrierung-abgeschlossen',
    PRIVACY_PAGE: '/datenschutz',
    ERROR_404: '/fehler-404',
    IMPRINT_PAGE: '/impressum',
    TERMS_PAGE: '/agb',
}

export default routes
