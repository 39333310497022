import React, { ChangeEvent } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import classNames from 'classnames'
import { ReactComponent as GreencheckmarkIcon } from '../../../assets/greencheckmark.svg'
import { ReactComponent as Times2Icon } from '../../../assets/times2.svg'
import './FormInput.scss'

interface Props {
    className?: string
    name?: string
    label?: string | null
    helper?: string
    error?: string | null
    valid?: boolean
    type?: string
    onChange?: (evt: ChangeEvent<HTMLInputElement>) => void
    value?: string
    multiline?: boolean
    rows?: number
    maxLength?: number
}

const FormInput = (props: Props) => {
    return (
        <div className={classNames('form-input', props.className)}>
            {props.label && <p className="form-input__label">{props.label}</p>}
            <TextField
                name={props.name}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                error={Boolean(props.error)}
                fullWidth
                multiline={props.multiline}
                rows={props.rows}
                sx={{
                    '& .Mui-focused': {
                        borderColor: '#64b4ff',
                    },
                    '& .Mui-error': {
                        borderColor: '#f04678',
                    },
                    '& .MuiFormHelperText-root': {
                        paddingTop: '5px',
                        fontSize: '10px',
                        color: '#21333a',
                        '&.Mui-error': {
                            color: '#f04678',
                        },
                    },
                    '& .MuiFormLabel-root': {
                        '&.Mui-error': {
                            color: '#f04678',
                        },
                    },
                }}
                variant="standard"
                inputProps={{ maxLength: props.maxLength }}
                InputProps={{
                    style: {
                        // caretColor: '#64b4ff',
                        padding: props.multiline ? '5px 0 5px 12px' : '0px 12px 0 12px',
                    },
                    className: classNames(
                        'form-input__textfield',
                        props.valid && 'form-input__textfield-success'
                    ),
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            {props.valid && (
                                <GreencheckmarkIcon className="form-input__check-icon" />
                            )}
                            {props.error && (
                                <Times2Icon className="form-input__close-icon" />
                            )}
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{ shrink: true }}
                helperText={
                    props.error ? (
                        <>{props.error}</>
                    ) : props.helper ? (
                        <>{props.helper}</>
                    ) : (
                        '\u00a0'
                    )
                }
            />
        </div>
    )
}

export default FormInput
