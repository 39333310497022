import React, { useEffect, useState } from 'react'
import FormInput from '../../atoms/FormInput/FormInput'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import './RegistrationStep2.scss'
import { validateInputs, validations } from '../../../utils/validations'
import FormAutocomplete from '../../atoms/FormAutocomplete/FormAutocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../../types/IRootState'
import setFormDataAction from '../../../state/actions/setFormData'
import { IApplicantData } from '../../../types/IApplicantData'
import setApplicantKey from '../../../state/actions/setApplicantKey'
import { ILanguage } from '../../../types/ILanguage'

interface Props {
    onSubmit: () => void
}

type ApplicantDataStatus = 'uninitialized' | 'requested' | 'received' | 'error'

const RegistrationStep2 = (props: Props) => {
    const [errors, setErrors] = useState<any>({})
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [languages, setLanguages] = useState<ILanguage[]>([])
    const formData = useSelector((state: IRootState) => state.form.formData)
    const [applicantDataStatus, setApplicantDataStatus] = useState<ApplicantDataStatus>('uninitialized')
    const [applicantData, setApplicantData] = useState<IApplicantData>({
        firstname: '',
        surname: '',
        status: '',
    })
    const dispatch = useDispatch()

    const formChange = (data: any) => {
        dispatch(setFormDataAction(data))
    }

    const urlParams = new URLSearchParams(window.location.search)
    const applicantKey = urlParams.get('key')

    useEffect(() => {
        let abortController = new AbortController()
        const dataFetch = async () => {
            setApplicantDataStatus('requested')

            const endpoints = ['/api/v1/frontend/validate-applicant?key=' + applicantKey, '/api/v1/frontend/languages']
            // eslint-disable-next-line
            const [data, languages] = await Promise.all(
                endpoints.map(
                    async (endpoint) =>
                        await (
                            await fetch(endpoint, {
                                signal: abortController.signal,
                            })
                        ).json()
                )
            )

            setApplicantDataStatus(data.success ? 'received' : 'error')

            setApplicantData({
                firstname: data.firstname,
                surname: data.surname,
                status: data.status,
            })

            setLanguages(languages.languages)
        }

        dataFetch()
        dispatch(setApplicantKey(applicantKey))
        return () => {
            abortController.abort()
        }
    }, [applicantKey, dispatch])

    const submit = () => {
        setSubmitted(true)

        const validateFields = {
            languages: [validations.required],
        }

        const errorList = validateInputs(validateFields, { ...formData, languages: formData.languages.length })
        setErrors(errorList)

        if (Object.keys(errorList).length === 0) {
            // success
            props.onSubmit()
        }
    }

    return (
        <div className="registration-step-2 registration-step-2--col-span-2">
            {(applicantDataStatus === 'uninitialized' || applicantDataStatus === 'requested') && (
                <div>Lade Daten...</div>
            )}
            {applicantDataStatus === 'error' && (
                <div>
                    Die aufgerufene ID existiert nicht mehr. Hast du dich vor mehr als 14 Tagen registriert? Dann sende
                    uns deine Registrierung einfach erneut.
                </div>
            )}
            {applicantDataStatus === 'received' &&
                applicantData.status !== 'registered' &&
                applicantData.status !== 'email_verified' && (
                    <div>Du hast die Registrierung bereits abgeschlossen.</div>
                )}
            {applicantDataStatus === 'received' &&
                (applicantData.status === 'registered' || applicantData.status === 'email_verified') && (
                    <>
                        <div className="registration-step-2__headline">Kontaktinformationen</div>
                        <div className="registration-step-2__container">
                            <FormInput
                                name="phone"
                                label="Telefonnummer"
                                error={errors['phone']}
                                valid={submitted && !errors['phone']}
                                value={formData.phone}
                                onChange={(evt) =>
                                    formChange({
                                        phone: evt.target.value,
                                    })
                                }
                            />
                            {/* <FormInput
                                name="contact_mobile"
                                label="Mobilfunknummer*"
                                error={errors['phone_mobile']}
                                valid={submitted && !errors['phone_mobile']}
                                value={formData.phone_mobile}
                                onChange={(evt) =>
                                    formChange({
                                        phone_mobile: evt.target.value,
                                    })
                                }
                            /> */}

                            <FormAutocomplete
                                multiple
                                value={formData.languages}
                                options={languages}
                                label="Sprachen in denen telefoniert werden kann*"
                                getOptionLabel={(option) => option.language}
                                onChange={(evt, newValue) =>
                                    formChange({
                                        languages: newValue.map((option) => option),
                                    })
                                }
                                error={errors['languages']}
                                valid={submitted && !errors['languages']}
                            />
                        </div>

                        <PrimaryButton className="registration-step-2__register-button" onClick={() => submit()}>
                            Weiter
                        </PrimaryButton>
                    </>
                )}
        </div>
    )
}

export default RegistrationStep2
