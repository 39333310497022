import { actionIds, BaseAction } from '../common'
import { IFormState } from '../../types/IFormState'

const defaultState: IFormState = {
    applicantKey: null,
    formData: {
        phone: '',
        phone_mobile: '',
        has_recent_os: 'unknown',
        has_remote_desktop_app: 'unknown',
        has_recent_cpu: 'unknown',
        has_sufficient_ram: 'unknown',
        has_fast_internet: 'unknown',
        has_hd_webcam: 'unknown',
        has_anc_headset: 'unknown',
        has_sufficient_resolution: 'unknown',
        has_german_keyboardlayout: 'unknown',
        office_peripherals: 'unknown',
        office_lock: 'unknown',
        office_privacy: 'unknown',
        office_notes: '',
        freelance_notes: '',
        vat_id: '',
        file_identification: [],
        file_address: [],
        file_license: [],
        files_office_photos: [],
        languages: [],
        addresses: [],
    },
    registrationStep: 2,
    countries: [],
}

const formReducer = (state: IFormState = defaultState, action: BaseAction): IFormState => {
    switch (action.type) {
        case actionIds.SET_APPLICANT_KEY:
            return {
                ...state,
                applicantKey: action.payload,
            }
        case actionIds.SET_FORM_DATA:
            return {
                ...state,
                formData: {
                    ...state.formData,
                    ...action.payload,
                },
            }

        case actionIds.CLEAR_FORM_DATA:
            return {
                ...state,
                formData: {
                    ...defaultState.formData,
                },
            }

        case actionIds.RESET_STORE:
            return {
                ...defaultState,
            }

        case actionIds.SET_REGISTRATION_STEP:
            return {
                ...state,
                registrationStep: action.payload,
            }

        case actionIds.SET_COUNTRIES:
            return {
                ...state,
                countries: action.payload,
            }

        default:
            return state
    }
}

export default formReducer
