import { all, call, ForkEffect, spawn } from 'redux-saga/effects'

type TSaga = (params?: any) => Generator<ForkEffect<never>, void, unknown>

interface IRootSagaParams {
    history: any
}

const rootSaga = function* root(params: IRootSagaParams) {
    const sagas: TSaga[] = []

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga, params)
                        break
                    } catch (e) {
                        console.error(e)
                    }
                }
            })
        )
    )
}

export default rootSaga
