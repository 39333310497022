import React, { ReactNode } from 'react'
import './PageLayout.scss'
import classNames from 'classnames'
import Content from '../../molecules/Content/Content'
import Header from '../../molecules/Header/Header'
import Footer from '../../molecules/Footer/Footer'
import FooterInfoText from '../../molecules/FooterInfoText/FooterInfoText'

interface Props {
    className?: string
    useAnchorLinks?: boolean
    isHome?: boolean
    children?: ReactNode
}

const PageLayout = (props: Props) => {
    return (
        <div className={classNames('page-layout', props.className)}>
            <Header useAnchorLinks={props.useAnchorLinks} />
            <Content>{props.children}</Content>
            <FooterInfoText isHome={props.isHome} />
            <Footer />
        </div>
    )
}

export default PageLayout
