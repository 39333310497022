import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import './RegistrationStep4.scss'
import { validateInputs } from '../../../utils/validations'
import FormRadioGroup from '../../atoms/FormRadioGroup/FormRadioGroup'
import FormInput from '../../atoms/FormInput/FormInput'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../../types/IRootState'
import setFormDataAction from '../../../state/actions/setFormData'
import setRegistrationStepAction from '../../../state/actions/setRegistrationStep'
import SecondaryButton from '../../atoms/SecondaryButton/SecondaryButton'
import { IFormData, TFormAction } from '../../../types/IFormState'
import { requirementList } from '../../../config/ListItems'
import { boolToYesUnknown, hardwareInfo } from '../../../utils/hardwareInfo'

interface Props {
    onSubmit: () => void
}

const RegistrationStep4 = (props: Props) => {
    const [errors, setErrors] = useState<any>({})
    const formData = useSelector((state: IRootState) => state.form.formData)
    const registrationStep = useSelector((state: IRootState) => state.form.registrationStep) as number
    const [submitted, setSubmitted] = useState<boolean>(false)
    const CHARACTER_LIMIT: number = 500
    const dispatch = useDispatch()

    useEffect(() => {
        const getHardwareInfo = async () => {
            const userHardware = await hardwareInfo()
            formChange({
                has_recent_os: boolToYesUnknown(userHardware.has_recent_os),
                has_sufficient_ram: boolToYesUnknown(userHardware.has_sufficient_ram),
                has_sufficient_resolution: boolToYesUnknown(userHardware.has_sufficient_resolution),
            })
        }
        getHardwareInfo()
        // eslint-disable-next-line
    }, [])

    const formChange = (data: Partial<IFormData>) => {
        dispatch(
            setFormDataAction({
                ...formData,
                ...data,
            })
        )
    }

    const submit = () => {
        setSubmitted(true)

        const validateFields = {}

        const errorList = validateInputs(validateFields, formData)
        setErrors(errorList)

        if (Object.keys(errorList).length === 0) {
            // success
            props.onSubmit()
        }
    }

    return (
        <div className="registration-step-4 registration-step-4--col-span-2">
            <div className="registration-step-4__headline">Erforderliche Arbeitsmittel für die Zusammenarbeit</div>
            <div className="registration-step-4__subline">
                Bitte kreuze an, welche Arbeitsmittel dir bereits zur Verfügung stehen:
            </div>
            {requirementList.map((checklist) => {
                return (
                    <div className="registration-step-4__container" key={checklist.key}>
                        <FormRadioGroup
                            label={
                                <>
                                    <p>{checklist.label}</p>
                                </>
                            }
                            value={formData[checklist.key]}
                            onChange={(evt) =>
                                formChange({
                                    [checklist.key]: evt.target.value as TFormAction,
                                })
                            }
                        />
                    </div>
                )
            })}
            <div className="registration-step-4__container">
                <FormInput
                    name=""
                    label="Anmerkungen zum Arbeitsraum (optional)"
                    error={''}
                    valid={submitted && !errors['']}
                    value={formData.office_notes}
                    onChange={(evt) => formChange({ office_notes: evt.target.value })}
                    rows={4}
                    multiline
                    maxLength={CHARACTER_LIMIT}
                    helper={`${formData.office_notes?.length || 0} / ${CHARACTER_LIMIT} Zeichen`}
                    className="registration-step-4__form-input-helper"
                />
            </div>
            <div className="registration-step-4__button-container">
                <SecondaryButton onClick={() => dispatch(setRegistrationStepAction(registrationStep - 1))}>
                    Zurück
                </SecondaryButton>
                <PrimaryButton onClick={() => submit()}>Weiter</PrimaryButton>
            </div>
        </div>
    )
}

export default RegistrationStep4
