import { combineReducers } from 'redux'
import { IRootState } from '../../types/IRootState'
import configReducer from './configReducer'
import connectionReducer from './connectionReducer'
import testresultsReducer from './testresultsReducer'
import formReducer from './formReducer'
import notificationReducer from './notificationReducer'

const rootReducer = combineReducers<IRootState>({
    config: configReducer,
    connection: connectionReducer,
    testresults: testresultsReducer,
    form: formReducer,
    notifications: notificationReducer,
})

export default rootReducer
