import classNames from 'classnames'
import React, { ReactNode } from 'react'
import './Headline.scss'

interface Props {
    className?: string
    children?: ReactNode
    preTitle?: ReactNode
}

const Headline = (props: Props) => {
    return (
        <h2 className={classNames('headline', props.className)}>
            <span className="headline-preheadline">{props.preTitle}</span>
            {props.children}
        </h2>
    )
}

export default Headline
