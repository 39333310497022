import React, { ReactNode } from 'react'
import classNames from 'classnames'
import Headline from '../../atoms/Headline/Headline'
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer'
import './ReferenceBox.scss'

interface Props {
    id?: string
    title: string
    className?: string
    children?: ReactNode
}

const ReferenceBox = (props: Props) => {
    return (
        <div
            className={classNames('reference-box', props.className)}
            id={props.id}
        >
            <LayoutContainer>
                <Headline className={props.className}>{props.title}</Headline>
                {props.children}
            </LayoutContainer>
        </div>
    )
}

export default ReferenceBox
