import React, { ReactNode } from 'react'
import classNames from 'classnames'
import './PrimaryButton.scss'

interface Props {
    className?: string
    onClick?: (e: React.MouseEvent) => void
    children: ReactNode
}

const PrimaryButton = (props: Props) => {
    return (
        <button
            className={classNames('primary-button', props.className)}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
}

export default PrimaryButton
