import zertifikat from '../assets/zertifikat.svg'
import quality from '../assets/quality.svg'
import omnichannel from '../assets/omnichannel.svg'
import headsetbox from '../assets/headsetbox.svg'
import { ReactComponent as flaglight } from '../assets/flag-light.svg'
import { ReactComponent as briefcaselight } from '../assets/briefcase-light.svg'
import { ReactComponent as thumbsuplight } from '../assets/thumbs-up-light.svg'
import { ReactComponent as userheadsetlight } from '../assets/user-headset-light.svg'
import { ReactComponent as stairslight } from '../assets/stairs-light.svg'
import { ReactComponent as desktoplight } from '../assets/desktop-light.svg'
import { ReactComponent as sackdollarlight } from '../assets/sack-dollar-light.svg'
import { ReactComponent as clocklight } from '../assets/clock-light.svg'
import { ReactComponent as chartnetworklight } from '../assets/chart-network-light.svg'
import { ReactComponent as listchecklight } from '../assets/list-check-light.svg'
import { ReactComponent as scalebalancedlight } from '../assets/scale-balanced-light.svg'
import { ReactComponent as graduationcaplight } from '../assets/graduation-cap-light.svg'
import { ReactComponent as doorclosedlight } from '../assets/door-closed-light.svg'
import { ReactComponent as wifilight } from '../assets/wifi-light.svg'
import { ReactComponent as headset } from '../assets/headset.svg'
import { useTranslation } from 'react-i18next'
import colors from '../scss/_colors.scss'

export const ListWhatYouOffer = () => {
    const { t } = useTranslation()
    return [
        {
            icon: flaglight,
            color: colors.orange,
            text: t('what-you-offer.list.0'),
        },
        {
            icon: briefcaselight,
            color: colors.orange,
            text: t('what-you-offer.list.1'),
        },
        {
            icon: thumbsuplight,
            color: colors.orange,
            text: t('what-you-offer.list.2'),
        },
        {
            icon: userheadsetlight,
            color: colors.orange,
            text: t('what-you-offer.list.3'),
        },
        {
            icon: stairslight,
            color: colors.orange,
            text: t('what-you-offer.list.4'),
        },
        {
            icon: desktoplight,
            color: colors.orange,
            text: t('what-you-offer.list.5'),
        },
    ]
}

export const ListWhatWeOffer = () => {
    const { t } = useTranslation()
    return [
        {
            icon: sackdollarlight,
            color: colors.blue,
            text: t('what-we-offer.list.0'),
        },
        {
            icon: clocklight,
            color: colors.blue,
            text: t('what-we-offer.list.1'),
        },
        {
            icon: chartnetworklight,
            color: colors.blue,
            text: t('what-we-offer.list.2'),
        },
        {
            icon: listchecklight,
            color: colors.blue,
            text: t('what-we-offer.list.3'),
        },
        {
            icon: scalebalancedlight,
            color: colors.blue,
            text: t('what-we-offer.list.4'),
        },
        {
            icon: graduationcaplight,
            color: colors.blue,
            text: t('what-we-offer.list.5'),
        },
    ]
}

export const ListRequirements = () => {
    const { t } = useTranslation()
    return [
        {
            icon: desktoplight, // TODO: change color
            color: colors.magenta,
            text: t('requirements.list.0'),
        },
        {
            icon: doorclosedlight,
            color: colors.magenta,
            text: t('requirements.list.1'),
        },
        {
            icon: headset,
            color: colors.magenta,
            text: t('requirements.list.2'),
        },
        {
            icon: wifilight,
            color: colors.magenta,
            text: t('requirements.list.3'),
        },
    ]
}

export const ListWhoWeAre = () => {
    const { t } = useTranslation()
    return [
        {
            icon: zertifikat,
            title: t('who-we-are.list.0.title'),
            description: t('who-we-are.list.0.description'),
        },
        {
            icon: quality,
            title: t('who-we-are.list.1.title'),
            description: t('who-we-are.list.1.description'),
        },
        {
            icon: omnichannel,
            title: t('who-we-are.list.2.title'),
            description: <div dangerouslySetInnerHTML={{ __html: t('who-we-are.list.2.description') }}></div>,
        },
        {
            icon: headsetbox,
            title: t('who-we-are.list.3.title'),
            description: t('who-we-are.list.3.description'),
        },
    ]
}

export const requirementList = [
    {
        label: 'Betriebssystem: Windows 10 oder 11, Mac oder Linux',
        key: 'has_recent_os',
    },
    {
        label: 'Vorhandensein einer Remote-Desktop-Anwendung',
        key: 'has_remote_desktop_app',
    },
    {
        label: 'Aktuelle CPU, wie beispielsweise ein i3-Prozessor',
        key: 'has_recent_cpu',
    },
    {
        label: '8GB RAM-Speicher',
        key: 'has_sufficient_ram',
    },
    {
        label: 'Eine Bandbreite von mindestens 16 MBit/s Download- und 4 MBit/s Uploadgeschwindigkeit ist erforderlich',
        key: 'has_fast_internet',
    },
    {
        label: 'Eine HD-Webcam ist optional, aber empfehlenswert',
        key: 'has_hd_webcam',
    },
    {
        label: 'Ein Headset mit Geräuschunterdrückung ist ideal',
        key: 'has_anc_headset',
    },
    {
        label: 'Eine minimale Auflösung von 1280x1024 wird empfohlen',
        key: 'has_sufficient_resolution',
    },
    {
        label: 'Das Tastaturlayout sollte auf Deutsch eingestellt sein, um Umlaute und das ß verwenden zu können',
        key: 'has_german_keyboardlayout',
    },
    {
        label: 'Notwendiges Zubehör steht für die Arbeit am PC zur Verfügung',
        key: 'office_peripherals',
    },
    {
        label: 'Mein Arbeitsraum ist abschließbar',
        key: 'office_lock',
    },
    {
        label: 'Der Arbeitsbereich ist nicht durch in Fenster einsehbar',
        key: 'office_privacy',
    },
]
