import React, { useEffect, useState } from 'react'
import FormInput from '../../atoms/FormInput/FormInput'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import './RegistrationStep1.scss'
import { validateInputs, validations } from '../../../utils/validations'
import PaperPlane from '../../../assets/paperplane.svg'
import Checkbox from '../../atoms/Checkbox/Checkbox'
import Routes from '../../../config/routes'
import { useTranslation } from 'react-i18next'
import FormAutocomplete from '../../atoms/FormAutocomplete/FormAutocomplete'
import { useDispatch, useSelector } from 'react-redux'
import setCountriesAction from '../../../state/actions/setCountries'
import { IRootState } from '../../../types/IRootState'

interface Props {}

declare global {
    interface Window {
        dataLayer: Record<string, any>[]
    }
}

const RegistrationStep1 = (props: Props) => {
    const { t } = useTranslation()
    const [errors, setErrors] = useState<any>({})
    const [formData, setFormData] = useState<any>({})
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [showThankYou, setShowThankYou] = useState<boolean>(false)
    const countries = useSelector((state: IRootState) => state.form.countries)
    const dispatch = useDispatch()

    const formChange = (data: any) => {
        setFormData({
            ...formData,
            ...data,
        })
    }

    useEffect(() => {
        let abortController = new AbortController()
        const dataFetch = async () => {
            const data = await (await fetch('/api/v1/frontend/countries', { signal: abortController.signal })).json()

            dispatch(setCountriesAction(data.countries))
        }

        dataFetch()
        return () => {
            abortController.abort()
        }
    }, [dispatch])

    const submit = () => {
        setSubmitted(true)

        const validateFields = {
            firstname: [validations.required, validations.alphaNumeric, validations.minLength(2)],
            surname: [validations.required],
            email: [validations.required, validations.email],
            email2: [validations.required],
            country_id: [validations.required, validations.numeric],
            birthday: [validations.required, validations.minAge(18)],
            phone_mobile: [validations.required, validations.phoneNumber],
            terms: [validations.required],
            terms2: [validations.required],
            terms3: [validations.required],
        }

        const errorList = validateInputs(validateFields, formData)

        if (formData.email !== formData.email2) {
            errorList['email2'] = 'Die E-Mail Adressen müssen übereinstimmen'
        }

        setErrors(errorList)

        if (Object.keys(errorList).length === 0) {
            // success
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            }
            fetch('/api/v1/frontend/register', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setShowThankYou(true)
                        window.dataLayer.push({
                            event: 'generate_lead',
                        })
                    } else {
                        if (data.error === 'Duplicate detected') {
                            errorList['email'] = 'Für diese E-Mail existiert bereits eine Bewerbung.'
                            setSubmitted(false)
                            setErrors(errorList)
                        } else {
                            alert(data.error ?? data)
                        }
                    }
                })
        }
    }

    return (
        <div className="registration-step-1 registration-step-1--col-span-2">
            {showThankYou && (
                <div className="registration-step-1__thank-you-overlay">
                    <div className="registration-step-1__thank-you-overlay__content">
                        <img
                            src={PaperPlane}
                            alt="Papierflieger"
                            className="registration-step-1__thank-you-overlay__icon"
                        />
                        <div className="registration-step-1__thank-you-overlay__headline">
                            Vielen Dank für die Registrierung
                        </div>
                        <div className="registration-step-1__thank-you-overlay__subline">
                            In Kürze erhältst du eine E-Mail von uns zur Bestätigung.
                        </div>
                        <div className="registration-step-1__thank-you-overlay__subline">
                            Bitte beachte: Du hast sieben Tage Zeit, um deine Registrierung abzuschließen,
                            <br />
                            andernfalls werden deine Daten gelöscht.
                        </div>
                    </div>
                </div>
            )}
            <p className="registration-step-1--required">* {t('registration.required')}</p>
            <div className="registration-step-1__container">
                <FormInput
                    name="firstname"
                    label={t('registration.firstname')}
                    error={errors['firstname']}
                    valid={submitted && !errors['firstname']}
                    onChange={(evt) => formChange({ firstname: evt.target.value })}
                />
                <FormInput
                    name="surname"
                    label={t('registration.surname')}
                    error={errors['surname']}
                    valid={submitted && !errors['surname']}
                    onChange={(evt) => formChange({ surname: evt.target.value })}
                />
                <FormInput
                    name="email"
                    label={t('registration.email')}
                    error={errors['email']}
                    valid={submitted && !errors['email']}
                    onChange={(evt) => formChange({ email: evt.target.value })}
                />
                <FormInput
                    name="email2"
                    label={t('registration.email2')}
                    error={errors['email2']}
                    valid={submitted && !errors['email2']}
                    onChange={(evt) => formChange({ email2: evt.target.value })}
                />
                <FormInput
                    name="contact_mobile"
                    label={t('registration.mobile')}
                    error={errors['phone_mobile']}
                    valid={submitted && !errors['phone_mobile']}
                    onChange={(evt) => formChange({ phone_mobile: evt.target.value })}
                />
                <div className="registration-step-1__desktop-spacer"></div>
                <FormAutocomplete
                    name="country_id"
                    label={t('registration.country')}
                    error={errors['country_id']}
                    valid={submitted && !errors['country_id']}
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    onChange={(evt, newValue) => formChange({ country_id: parseInt(newValue.id) })}
                />
                <FormInput
                    name="birthday"
                    label={t('registration.birthday')}
                    type="date"
                    error={errors['birthday']}
                    valid={submitted && !errors['birthday']}
                    onChange={(evt) => formChange({ birthday: evt.target.value })}
                />
            </div>
            &nbsp;
            <Checkbox
                id={'1'}
                label={
                    <p>
                        {t('registration.terms.pre') + ' '}
                        <a href={Routes.TERMS_PAGE} target="_blank" rel="noopener noreferrer">
                            {t('registration.terms.link')}
                        </a>
                        {' ' + t('registration.terms.post')}
                    </p>
                }
                error={errors['terms']}
                onChange={(evt) => formChange({ terms: evt.target.checked })}
            />
            <Checkbox
                id={'2'}
                label={
                    <p>
                        {t('registration.privacy.pre') + ' '}
                        <a href={Routes.PRIVACY_PAGE} target="_blank" rel="noopener noreferrer">
                            {t('registration.privacy.link')}
                        </a>
                        {' ' + t('registration.privacy.post')}
                    </p>
                }
                error={errors['terms2']}
                onChange={(evt) => formChange({ terms2: evt.target.checked })}
            />
            <Checkbox
                id={'3'}
                label={<p>{t('registration.language')}</p>}
                error={errors['terms3']}
                onChange={(evt) => formChange({ terms3: evt.target.checked })}
            />
            <PrimaryButton className="registration-step-1__register-button" onClick={() => submit()}>
                {t('registration.cta')}
            </PrimaryButton>
            &nbsp;
        </div>
    )
}

export default RegistrationStep1
