import React, { ChangeEvent, ReactNode } from 'react'
import classNames from 'classnames';
import './Checkbox.scss'

interface Props {
    className?: string
    id?: string
    label?: ReactNode
    error?: boolean
    onChange?: (evt: ChangeEvent<HTMLInputElement>) => void
}

const Checkbox = (props: Props) => {
    return (
        <div
            className={classNames('checkbox-container', props.className)}
            onChange={props.onChange}
        >
            <input
                id={props.id}
                type='checkbox'
                name='checkbox__input'
            />
            <label
                htmlFor={props.id}
                className={classNames('checkbox', props.error && 'checkbox-error')}
            >
                {props.label}
            </label>
        </div>
    )
}

export default Checkbox