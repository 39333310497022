import React, { useEffect } from 'react'
import classNames from 'classnames'
import { Fab, FormControl, FormHelperText, IconButton } from '@mui/material'
import './FormInputFile.scss'
import { FileContent, FileError, useFilePicker } from 'use-file-picker'
import { UploadFileInfo } from '../../../types/IFormState'

interface Props {
    className?: string
    name?: string
    label?: string
    helper?: string
    error?: string
    onChange?: (evt: FileContent[]) => void
    onError?: (evt: string) => void
    onDeleteFile: (evt) => void
    data?: any
    multiple?: boolean
}

const FormInputFile = (props: Props) => {
    let fileData = props.data
    const mimeType = ['png', 'jpeg', 'jpg'] as const

    const [openFileSelector, { filesContent, errors, clear }] = useFilePicker({
        readAs: 'DataURL',
        accept: ['.png', '.pdf', 'image/*'],
        multiple: props.multiple ?? false,
        limitFilesConfig: { max: 4 },
        maxFileSize: 3,
    })

    useEffect(() => {
        if (filesContent.length > 0) {
            props.onChange && props.onChange(filesContent)
            clear()
        }
        if (errors.length > 0) {
            props.onError && props.onError(handleErrorMsg(errors))
            clear()
        }
        // eslint-disable-next-line
    }, [errors, filesContent])

    const handleErrorMsg = (errors: FileError[]) => {
        switch (Object.keys(errors[0])[0]) {
            case 'fileSizeToolarge':
                return 'The file exceeds the maximum allowed size 3MB'

            case 'maxLimitExceeded':
                return 'Exceeds the maximum of limit of 4 files'

            case 'readerError':
                return 'Problem occured while reading file'

            default:
                return ''
        }
    }

    return (
        <div className={classNames('form-input-file', props.className)}>
            <FormControl
                fullWidth
                sx={{
                    '& .MuiFormHelperText-root': {
                        margin: 0,
                        fontSize: '10px',
                        color: '#21333a',
                        '&.Mui-error': {
                            color: '#f04678',
                        },
                    },
                }}
            >
                {props.label && <p className="form-input-file__label">{props.label}</p>}
                {!fileData?.length ? (
                    <div className="form-input-file__card">
                        <IconButton component="span" onClick={() => openFileSelector()}>
                            <svg
                                style={{
                                    width: '24px',
                                    height: '24px',
                                }}
                                viewBox="0 0 576 512"
                            >
                                <path
                                    fill="#c4c4c4"
                                    d="M572.694 292.093L500.27 416.248A63.997 63.997 0 0 1 444.989 448H45.025c-18.523 0-30.064-20.093-20.731-36.093l72.424-124.155A64 64 0 0 1 152 256h399.964c18.523 0 30.064 20.093 20.73 36.093zM152 224h328v-48c0-26.51-21.49-48-48-48H272l-64-64H48C21.49 64 0 85.49 0 112v278.046l69.077-118.418C86.214 242.25 117.989 224 152 224z"
                                />
                            </svg>
                        </IconButton>
                    </div>
                ) : (
                    <>
                        {fileData.map((file: UploadFileInfo, i) => {
                            return (
                                <div
                                    key={i}
                                    className={classNames(
                                        'form-input-file__card',
                                        props.error ? 'form-input-file__card--error' : ''
                                    )}
                                >
                                    {mimeType.some((el) => file.name.includes(el as typeof mimeType[number])) && (
                                        <img src={file.content} alt="" className="form-input-file__card-img" />
                                    )}
                                    {file.name.includes('pdf') && (
                                        <object
                                            width="100%"
                                            height="100%"
                                            data={file.content}
                                            type="application/pdf"
                                            aria-label="pdf"
                                        />
                                    )}
                                    <Fab
                                        value={file.document_id}
                                        color="primary"
                                        size="small"
                                        aria-label="close"
                                        style={{
                                            position: 'absolute',
                                            top: 10,
                                            right: 10,
                                            backgroundColor: '#F50A00',
                                        }}
                                        onClick={props.onDeleteFile}
                                    >
                                        x
                                    </Fab>
                                </div>
                            )
                        })}
                    </>
                )}
                <FormHelperText>
                    {props.error ? (
                        <span style={{ color: '#f04678' }}>{props.error}</span>
                    ) : props.helper ? (
                        <>{props.helper}</>
                    ) : (
                        '\u00a0'
                    )}
                </FormHelperText>
            </FormControl>
        </div>
    )
}

export default FormInputFile
