import React, { useState } from 'react'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import './RegistrationStep5.scss'
import { validateInputs, validations } from '../../../utils/validations'
import FormInput from '../../atoms/FormInput/FormInput'
import FormInputFile from '../../atoms/FormInputFile/FormInputFile'
import { useDispatch, useSelector } from 'react-redux'
import setNotificationAction from '../../../state/actions/setNotification'
import { IRootState } from '../../../types/IRootState'
import setFormDataAction from '../../../state/actions/setFormData'
import { useNavigate } from 'react-router-dom'
import Routes from '../../../config/routes'
import setRegistrationStepAction from '../../../state/actions/setRegistrationStep'
import SecondaryButton from '../../atoms/SecondaryButton/SecondaryButton'
import { TDocument, TFormDocument, UploadFileInfo } from '../../../types/IFormState'
import { FileContent } from 'use-file-picker'

interface Props {
    onSubmit: () => void
}

const RegistrationStep5 = (props: Props) => {
    // eslint-disable-next-line
    const [errors, setErrors] = useState<any>({})
    // eslint-disable-next-line
    const [submitted, setSubmitted] = useState<boolean>(false)
    const CHARACTER_LIMIT: number = 500
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const applicantKey = useSelector((state: IRootState) => state.form.applicantKey)
    const formData = useSelector((state: IRootState) => state.form.formData)
    const registrationStep = useSelector((state: IRootState) => state.form.registrationStep) as number
    let isSubmitting: number = 0

    const formChange = (data: any) => {
        dispatch(
            setFormDataAction({
                ...formData,
                ...data,
            })
        )
    }

    const handleDeleteFile = (evt, formName: TFormDocument) => {
        // Disallowing overlapping requests
        // TODO: debounce or disable the button while the http request is running
        if (isSubmitting > 0) return
        ++isSubmitting

        let selectedFile = Number(evt.target.value)
        let document_id = formData[formName].find(
            (file: UploadFileInfo) => file.document_id === selectedFile
        )?.document_id

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                applicant_key: applicantKey,
                document_id: document_id,
            }),
        }
        fetch('/api/v1/frontend/delete-document', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    --isSubmitting
                    formChange({
                        [formName]: [
                            ...formData[formName].filter((file: UploadFileInfo) => file.document_id !== selectedFile),
                        ],
                    })
                } else {
                    alert(data.error ?? data)
                }
            })
            .catch((err: unknown) => {
                if (err instanceof Error) {
                    --isSubmitting
                    throw new Error(err.message)
                }
            })
    }

    const handleChangeFile = (file: FileContent[], formName: TFormDocument, documentType: TDocument) => {
        const promises = file.map(async (filesContent: FileContent) => {
            let [fileInfo, fileContent] = filesContent.content.split(';base64,')
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    applicant_key: applicantKey,
                    document_type: documentType,
                    document: fileContent,
                    document_name: filesContent.name,
                    mime_type: fileInfo.replace('data:', ''),
                }),
            }
            return await fetch('/api/v1/frontend/upload-document', requestOptions).then((response) =>
                response.json().then((data: UploadFileInfo) => ({
                    ...filesContent,
                    ...data,
                }))
            )
        })

        Promise.all(promises)
            .then((data) => {
                if (data.length > 0) {
                    let errors = data.filter((data) => !data.success)
                    if (errors.length) {
                        errors.forEach((error) => {
                            alert(error.error)
                        })
                    } else {
                        formChange({
                            [formName]: [...data],
                        })
                    }
                }
            })
            .catch((err: unknown) => {
                if (err instanceof Error) {
                    throw new Error(err.message)
                }
            })
    }

    const handleFileError = (errorMsg) => {
        dispatch(
            setNotificationAction({
                created: String(new Date()),
                type: 'error',
                message: errorMsg,
            })
        )
    }

    const submit = () => {
        setSubmitted(true)

        const validateFields = {
            file_identification: [validations.required],
        }

        const errorList = validateInputs(validateFields, formData)
        setErrors(errorList)

        if (Object.keys(errorList).length === 0) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    applicant_key: applicantKey,
                    phone: formData.phone,
                    phone_mobile: formData.phone_mobile,
                    has_recent_os: formData.has_recent_os,
                    has_remote_desktop_app: formData.has_remote_desktop_app,
                    has_recent_cpu: formData.has_recent_cpu,
                    has_sufficient_ram: formData.has_sufficient_ram,
                    has_fast_internet: formData.has_fast_internet,
                    has_hd_webcam: formData.has_hd_webcam,
                    has_anc_headset: formData.has_anc_headset,
                    has_sufficient_resolution: formData.has_sufficient_resolution,
                    has_german_keyboardlayout: formData.has_german_keyboardlayout,
                    office_peripherals: formData.office_peripherals,
                    office_lock: formData.office_lock,
                    office_privacy: formData.office_privacy,
                    office_notes: formData.office_notes,
                    freelance_notes: formData.freelance_notes,
                    vat_id: formData.vat_id,
                    languages: formData.languages.map((language) => language.iso2), // NOTE: retrieve iso2 value
                    addresses: formData.addresses,
                }),
            }
            fetch('/api/v1/frontend/submit', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        // success
                        navigate(Routes.REGISTRATION_SUCCESS_PAGE)
                    } else {
                        alert(data.error ?? data)
                    }
                })
        }
    }

    return (
        <div className="registration-step-5 registration-step-5--col-span-2">
            <div className="registration-step-5__headline">Nachweis zur Selbstständigkeit</div>
            <div className="registration-step-5__container">
                <FormInput
                    name="identification"
                    label="Umsatzsteueridentifikationsnummer (optional)"
                    onChange={(evt) => formChange({ vat_id: evt.target.value })}
                    error={errors['vat_id']}
                    valid={submitted && !errors['vat_id']}
                    value={formData.vat_id}
                />
                <FormInputFile
                    label="Ausweisdokument"
                    onChange={(evt) => handleChangeFile(evt, 'file_identification', 'identification')}
                    onDeleteFile={(evt) => handleDeleteFile(evt, 'file_identification')}
                    onError={(errorMsg) => handleFileError(errorMsg)}
                    data={formData['file_identification']}
                    helper="PDF, JPG, PNG, max. 3 MB"
                    error={errors['file_identification']}
                />
                <FormInputFile
                    label="Adressdokument (optional)"
                    onChange={(evt) => handleChangeFile(evt, 'file_address', 'address')}
                    onDeleteFile={(evt) => handleDeleteFile(evt, 'file_address')}
                    onError={(errorMsg) => handleFileError(errorMsg)}
                    data={formData['file_address']}
                    helper="PDF, JPG, PNG, max. 3 MB"
                />
                <FormInputFile
                    label="Gewerbeschein (optional)"
                    onChange={(evt) => handleChangeFile(evt, 'file_license', 'license')}
                    onDeleteFile={(evt) => handleDeleteFile(evt, 'file_license')}
                    onError={(errorMsg) => handleFileError(errorMsg)}
                    data={formData['file_license']}
                    helper="PDF, JPG, PNG, max. 3 MB"
                />
                <FormInputFile
                    label="Bilder des Arbeitsraums und Arbeitsbereichs (optional)"
                    onChange={(evt) => handleChangeFile(evt, 'files_office_photos', 'office_photos')}
                    onDeleteFile={(evt) => handleDeleteFile(evt, 'files_office_photos')}
                    onError={(errorMsg) => handleFileError(errorMsg)}
                    data={formData['files_office_photos']}
                    helper="PDF, JPG, PNG, max. 3 MB pro Bild, max. 4 Bilder"
                    multiple
                />
                <FormInput
                    name=""
                    label="Anmerkungen (optional)"
                    // error={''}
                    // valid={submitted && !errors['']}
                    onChange={(evt) => formChange({ freelance_notes: evt.target.value })}
                    rows={4}
                    multiline
                    value={formData.freelance_notes}
                    maxLength={CHARACTER_LIMIT}
                    helper={`${formData.freelance_notes?.length || 0} / ${CHARACTER_LIMIT} Zeichen`}
                    className="registration-step-4__form-input-helper"
                />
            </div>
            <div className="registration-step-5__button-container">
                <SecondaryButton onClick={() => dispatch(setRegistrationStepAction(registrationStep - 1))}>
                    Zurück
                </SecondaryButton>
                <PrimaryButton onClick={() => submit()}>Registrierung abschließen</PrimaryButton>
            </div>
        </div>
    )
}

export default RegistrationStep5
