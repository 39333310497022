import vodafone from '../assets/brands/vodafone.svg'
import telekom from '../assets/brands/telekom.svg'
import mediamarkt from '../assets/brands/mediamarkt.svg'
import saturn from '../assets/brands/saturn.svg'
import otto from '../assets/brands/otto.svg'
import telefonica from '../assets/brands/telefonica.svg'
import freenet from '../assets/brands/freenet.svg'
import enbw from '../assets/brands/enbw.svg'
import lebara from '../assets/brands/lebara.svg'
import dew21 from '../assets/brands/dew21.svg'
import klarmobil from '../assets/brands/klarmobil.svg'
import fonic from '../assets/brands/fonic.svg'
import tele2 from '../assets/brands/tele2.svg'
import stadtenergie from '../assets/brands/stadtenergie.svg'
import EAM from '../assets/brands/EAM.webp'

export const brandIcons = [
    { id: 1, src: telekom, alt: 'Referenzenlogo Telekom' },
    { id: 2, src: vodafone, alt: 'Referenzenlogo Vodafone' },
    { id: 3, src: telefonica, alt: 'Referenzenlogo Telefonica' },
    { id: 4, src: freenet, alt: 'Referenzenlogo Freenet' },
    { id: 5, src: mediamarkt, alt: 'Referenzenlogo MediaMarkt' },
    { id: 6, src: dew21, alt: 'Referenzenlogo DEW21' },
    { id: 7, src: enbw, alt: 'Referenzenlogo EnBw' },
    { id: 8, src: lebara, alt: 'Referenzenlogo Lebara' },
    { id: 9, src: otto, alt: 'Referenzenlogo Otto' },
    { id: 10, src: saturn, alt: 'Referenzenlogo Saturn' },
    { id: 11, src: klarmobil, alt: 'Referenzenlogo Klarmobil' },
    { id: 12, src: fonic, alt: 'Referenzenlogo Fonic' },
    { id: 13, src: tele2, alt: 'Referenzenlogo tele2' },
    { id: 14, src: stadtenergie, alt: 'Referenzenlogo Stadtenergie' },
    { id: 15, src: EAM, alt: 'Referenzenlogo EAM', type: 'webp' },
]
