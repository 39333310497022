export const actionIds = {
    RESET_STORE: 'RESET_STORE',
    ERROR_ACCESS_DENIED: 'ERROR_ACCESS_DENIED',
    ERROR_INTERNAL_SERVER_ERROR: 'ERROR_INTERNAL_SERVER_ERROR',
    LOADING_SET: 'LOADING_SET',
    LOADING_UNSET: 'LOADING_UNSET',
    GET_CONFIG: 'GET_CONFIG',
    SET_CONFIG: 'SET_CONFIG',
    CONNECTED: 'CONNECTED',
    RECONNECT: 'RECONNECT',
    RECONNECT_ABORT: 'RECONNECT_ABORT',
    DISCONNECTED: 'DISCONNECTED',
    GET_TESTRESULTS: 'GET_TESTRESULTS',
    SET_TESTRESULTS: 'SET_TESTRESULTS',
    SET_FORM_DATA: 'SET_FORM_DATA',
    SET_APPLICANT_KEY: 'SET_APPLICANT_KEY',
    CLEAR_FORM_DATA: 'CLEAR_FORM_DATA',
    SET_NOTIFICATION: 'SET_NOTIFICATION',
    UNSET_NOTIFICATION: 'UNSET_NOTIFICATION',
    CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
    SET_REGISTRATION_STEP: 'SET_REGISTRATION_STEP',
    SET_COUNTRIES: 'SET_COUNTRIES'
}

export interface BaseAction<T = any> {
    type: string
    payload: T
}
