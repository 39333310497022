import React from 'react'
import './Hamburger.scss'

interface Props {
    className: string
    onClick: () => void
}

const Hamburger = (props: Props) => {
    return (
        <div className={props.className} onClick={props.onClick}>
            <div className="line__1"></div>
            <div className="line__2"></div>
            <div className="line__3"></div>
        </div>
    )
}

export default Hamburger
