import React from 'react'
import PageLayout from '../organisms/PageLayout/PageLayout'
import ContentBox from '../molecules/ContentBox/ContentBox'
import './ImprintPage.scss'

const ImprintPage = () => {
    return (
        <div className="imprint-page">
            <PageLayout>
                <ContentBox className="headline--blue" id="imprint">
                    <h1>Impressum</h1>
                    <hr />
                    <h2>HAFTUNGSAUSSCHLUSS</h2>
                    <p>
                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                        Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als
                        Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
                        nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
                        Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                        Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
                        hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
                        einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    </p>
                    <h2>HAFTUNG FÜR LINKS</h2>
                    <p>
                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                        Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als
                        Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
                        nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
                        Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                        Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
                        hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
                        einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    </p>
                    <h2>URHEBERRECHT</h2>
                    <p>
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                        deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                        Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten
                        nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                        erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
                        als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
                        werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                        werden wir derartige Inhalte umgehend entfernen.
                    </p>
                    <p>Dieses Impressum gilt auch für unseren Unternehmensauftritt bei Facebook und Xing.</p>

                    <p>&nbsp;</p>

                    <h2>S-KON Sales Kontor Hamburg GmbH</h2>
                    <p>
                        Gasstraße 4c
                        <br />
                        22761 Hamburg
                        <br />
                        Telefon: +49 40 40 130-0
                        <br />
                        Fax: +49 40 40 130-140
                        <br />
                        <a href="mailto:info@skon.de">info@skon.de</a>
                    </p>
                    <p>Geschäftsführer: Thomas Ott, Sebastian Leven</p>
                    <p>
                        Sitz d. Gesellschaft: Hamburg
                        <br />
                        Amtsgericht Hamburg, HRB 98815
                        <br />
                        Steuer-Nr. 41/759/02703 USt-IdNr. DE248947705
                    </p>
                </ContentBox>
            </PageLayout>
        </div>
    )
}

export default ImprintPage
