import React, { ReactNode } from 'react'
import classNames from 'classnames'
import './SecondaryButton.scss'

interface Props {
    className?: string
    onClick?: (e: React.MouseEvent) => void
    children: ReactNode
}

const SecondaryButton = (props: Props) => {
    return (
        <button className={classNames('secondary-button', props.className)} onClick={props.onClick}>
            {props.children}
        </button>
    )
}

export default SecondaryButton
