import classNames from 'classnames'

interface Props {
    className?: string
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    color: string
}

// NOTE: pass svg prop as ReactComponent
// e.g import { ReactComponent as foo } from '../assets/foo.svg'
const SvgComponent = (props: Props) => {
    const SvgIcon = props.icon

    return (
        <div className={classNames('SvgComponent', props.className)}>
            <SvgIcon className={props.className} color={props.color} />
        </div>
    )
}

export default SvgComponent
