import React, { ReactNode } from 'react'
import './Content.scss'
import classNames from 'classnames'

interface Props {
    className?: string
    children?: ReactNode
}

const Content = (props: Props) => {
    return (
        <div className={classNames('content-container', props.className)}>
            {props.children}
        </div>
    )
}

export default Content
