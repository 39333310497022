import { actionIds, BaseAction } from '../common'
import { ITestresultsState } from '../../types/ITestresultsState'

const defaultState: ITestresultsState = {
    isInitialized: false,
    db_connection: false,
    ldap_connection: false,
    sms_connection: false,
    mail_connection: false,
}

const testresultsReducer = (
    state: ITestresultsState = defaultState,
    action: BaseAction
): ITestresultsState => {
    switch (action.type) {
        case actionIds.SET_TESTRESULTS:
            return {
                ...state,
                isInitialized: true,
                ...action.payload,
            }

        default:
            return state
    }
}

export default testresultsReducer
