import React from 'react'
import PageLayout from '../organisms/PageLayout/PageLayout'
import ContentBox from '../molecules/ContentBox/ContentBox'
import './TermsPage.scss'

const TermsPage = () => {
    return (
        <div className="terms-page">
            <PageLayout>
                <ContentBox className="headline--blue" id="terms">
                    <h1>AGB</h1>
                    <hr />

                    <h3>1. Grundlagen</h3>
                    <p>
                        1.1 Die S-KON Sales Kontor Hamburg GmbH (nachfolgend kurz: S-KON) betreibt die Website
                        www.saleskontor.com (nachfolgend kurz: Website) und bietet Freelancern die Möglichkeit, sich
                        über die Website auf von S-KON angebotene Projekte zu bewerben.
                    </p>
                    <p>1.2 Freelancer sind natürliche Personen und handeln nicht als Verbraucher.</p>

                    <p>
                        1.3 Der Nutzung der Website liegen ausschließlich diese AGB zugrunde. Die Geltung anderer AGB,
                        insbesondere die Geltung von AGB des Freelancers, ist ausgeschlossen.
                    </p>

                    <p>
                        1.4 S-KON ist berechtigt, diese AGB zu ändern. Für den Fall, dass S-KON von diesem Recht
                        Gebrauch macht, wird S-KON alle auf der Website registrierten Nutzer mindestens 14 Tage
                        (Ankündigungsfrist) im Voraus über die geplante Änderung informieren, sofern dem keine
                        zwingenden rechtlichen Verpflichtungen entgegenstehen.
                    </p>

                    <p>
                        1.5 Sollte der Freelancer den geänderten AGB nicht innerhalb der Ankündigungsfrist
                        widersprechen, gelten die geänderten AGB als zwischen den Parteien vereinbart. Widerspricht der
                        Freelancer den geänderten AGB, gelten weiterhin die AGB zwischen den Parteien, die bereits zum
                        Zeitpunkt des Widerspruchs zwischen den Parteien galten. Im Falle eines Widerspruchs ist S-KON
                        jedoch berechtigt, die Vertragsbeziehung zwischen S-KON und dem Freelancer mit einer Frist von
                        zwei Wochen zu kündigen und diesem den Zugriff auf sein Nutzungskonto zu sperren.
                    </p>

                    <h3>2. Geltungsbereich</h3>
                    <p>
                        Diese AGB gelten für die Nutzung der Website. Dies beinhaltet unter anderem die kostenfreie
                        Erstellung eines Accounts, die anschließende Nutzung des S-KON Projektpools sowie sonstige
                        Funktionalitäten der Website.
                    </p>

                    <h3>3. Nutzungsberechtigung</h3>
                    <p>
                        Zur Nutzung der kostenfreien Dienste der Website sind alle natürlichen Personen berechtigt, die
                        freiberuflich oder selbstständig tätig sind und grundsätzlich für die
                        freiberufliche/selbstständige Durchführung von Projekten im Telefondienstleistungssektor zur
                        Verfügung stehen.
                    </p>

                    <h3>4. Registrierung</h3>
                    <p>
                        4.1 Um sich auf von S-KON angebotene Projekte bewerben zu können, ist es erforderlich, sich auf
                        der Website zu registrieren. Der Registrierungsvorgang umfasst folgende Schritte:
                    </p>

                    <p>
                        4.1.1 Angabe von Vor- und Nachnamen, E-Mail-Adresse, Land (von dem aus gearbeitet werden soll)
                        und Zustimmung zu diesen AGB
                    </p>
                    <p>4.1.2 Bestätigung der E-Mail-Adresse durch Anklicken des zugesandten Bestätigungslinks</p>
                    <p>
                        4.1.3 Vervollständigung der Kontaktdaten (Telefonnummer, Rechnungsadresse, Arbeitsadressen,
                        Bestätigung der Selbstständigkeit)
                    </p>
                    <p>
                        4.1.4 Angabe der vorhandenen eigenen Arbeitsmittel (Computer mit Zubehör, stabile
                        Internetverbindung, geeigneter Arbeitsraum)
                    </p>
                    <p>4.1.5 Datenupload zur Bestätigung der gemachten Angaben</p>
                    <p>4.1.6 Verification Call zur Überprüfung der Angaben</p>

                    <p>
                        4.2 Zur Überprüfung der Einhaltung der Datenschutzgrundverordnung (DSGVO) ist eine Zustimmung
                        zur Aufzeichnung von Telefongesprächen, die im Zuge der Projektbearbeitung geführt werden, sowie
                        eine projektbezogene Aufzeichnung der Webcam des Freelancers erforderlich.
                    </p>

                    <p>
                        4.3 Alle im Zuge der Registrierung geforderten Angaben sind vom Freelancer wahrheitsgemäß und
                        vollständig zu machen. Sollten sich die betreffenden Daten im Laufe der Geschäftsbeziehung
                        ändern, sind diese von dem Freelancer unverzüglich in seinem Benutzerprofil zu aktualisieren.
                        Sollte S-KON feststellen, dass gemachte Angaben falsch oder unvollständig sind oder ergeben sich
                        Verdachtsmomente, die diese Annahme nahelegen, ist S-KON berechtigt, die Registrierung
                        abzubrechen und/oder den bestehenden Account zu löschen und alle zwischen den Parteien
                        bestehenden Verträge fristlos aus wichtigem Grund zu kündigen.
                    </p>

                    <p>
                        4.4 Es besteht kein Anspruch auf Registrierung. S-KON ist daher auch berechtigt, ohne Angabe von
                        Gründen eine Registrierung auf der Website abzulehnen.
                    </p>

                    <p>
                        4.5 Im Rahmen der Registrierung erhält der Freelancer persönliche Zugangsdaten, um auf seinen
                        Account zugreifen zu können. Diese sind vor dem Zugriff durch Dritte zu schützen und dürfen
                        daher insbesondere Dritten nicht zugänglich gemacht werden. Auch darf das Benutzerkonto nicht
                        auf eine andere Person übertragen werden.
                    </p>

                    <p>4.6 Es ist Freelancern nicht gestattet, mehr als ein Benutzerkonto auf der Website anzulegen.</p>

                    <p>
                        4.7 Die Registrierung ist abgeschlossen, wenn die Webcam Session erfolgreich beendet und sowohl
                        Rahmenvertrag als auch Datenschutzvertrag vereinbart wurden.
                    </p>

                    <p>
                        4.8 Mit der Bestätigung seiner E-Mail-Adresse durch den Freelancer wird zwischen dem Freelancer
                        und S-KON ein Nutzungsvertrag über die Nutzung der Website auf unbestimmte Zeit geschlossen. Der
                        Nutzungsvertrag kann jederzeit von einer der Parteien mit einer Kündigungsfrist von 30 Tagen zum
                        Monatsende gekündigt werden. Ein Recht zur fristlosen Kündigung aus wichtigem Grund bleibt
                        unberührt. Kündigungen bedürfen zu ihrer Wirksamkeit der Textform.
                    </p>

                    <h3>5. Auftragserteilung</h3>
                    <p>
                        5.1 Nach erfolgreicher Registrierung kann der Freelancer die aktuellen Projekte im Projektpool
                        von S-KON einsehen und sich auf diese bewerben.
                    </p>

                    <p>
                        5.2 Die Projektverteilung im Projektpool läuft nach einem „early bird“ System. Daher erhält
                        derjenige Freelancer den Auftrag, der das Angebot zuerst annimmt und mit dem S-KON eine
                        Projektvereinbarung über die Projektübernahme abschließt. Ein Anspruch des Freelancers auf
                        Abschluss einer Projektvereinbarung besteht nicht.
                    </p>

                    <p>
                        5.3 Der Freelancer willigt ein, dass S-KON ihm auch direkt Projektangebote unterbreiten kann,
                        sofern der Freelancer die für das Projekt erforderlichen Leistungsanforderungen erfüllt. Auch in
                        diesem Fall kommt die Beauftragung erst mit Abschluss einer Projektvereinbarung zustande.
                    </p>

                    <h3>6. Datenschutz</h3>
                    <p>
                        6.1 Der Freelancer willigt ein, dass S-KON im Zuge des Registrierungsprozesses und im Rahmen der
                        Projektbearbeitung durch den Freelancer personenbezogene Daten von diesem verarbeitet (Art. 4
                        Nr. 2 DSGVO).
                    </p>

                    <p>
                        6.2 Auf der Website ist eine Datenschutzerklärung abrufbar. Diese bildet die Grundlage für die
                        Verarbeitung von personenbezogenen Daten des Freelancers.
                    </p>

                    <h3>7. Haftung</h3>
                    <p>
                        7.1 Bei Verletzung wesentlicher Vertragspflichten ist die Haftung von S-KON wegen leichter
                        Fahrlässigkeit auf den typischerweise vorhersehbaren Schaden begrenzt.
                    </p>

                    <p>
                        7.2 S-KON haftet nicht für ein fehlerfreies Funktionieren der Website. Auch besteht insoweit
                        keine Verpflichtung zu Mängelbeseitigung seitens S-KON.
                    </p>

                    <p>
                        7.3 Der Freelancer ist für jede von ihm zu vertretende Ermöglichung befugter oder unbefugter
                        Nutzung seines Profils und der darin gespeicherten Daten durch Dritte verantwortlich. Sollten
                        dem Freelancer oder Dritten hierdurch Schäden entstehen, ist eine Haftung von S-KON ausdrücklich
                        ausgeschlossen. Sollten S-KON durch die vorgenannte Nutzung Schäden entstehen, so sind diese
                        durch den Freelancer zu ersetzen.
                    </p>

                    <p>
                        7.4 Sollte S-KON einer zwingenden gesetzlichen Haftung, insbesondere nach dem
                        Produkthaftungsgesetz oder aufgrund schuldhaft verursachter Körperschäden, unterliegen, so
                        finden die vorgenannten Haftungsbeschränkungen insoweit keine Anwendung.
                    </p>

                    <h3>8. Schlussbestimmungen</h3>
                    <p>
                        8.1 Die Rechtsbeziehung der Parteien sowie etwaige Streitigkeiten aus diesen AGB richten sich
                        ausschließlich nach dem Recht der Bundesrepublik Deutschland.
                    </p>

                    <p>8.2 Gerichtsstand für alle Streitigkeiten aus diesen AGB ist Hamburg.</p>

                    <p>
                        8.3 Sollte eine Bestimmung dieser AGB ganz oder teilweise unwirksam sein oder werden oder sollte
                        sich eine Regelungslücke in diesen AGB herausstellen, wird hierdurch die Wirksamkeit der AGB im
                        Übrigen nicht berührt. Anstelle der unwirksamen Bestimmung und zur Ausfüllung von
                        Regelungslücken soll eine angemessene Regelung gelten, die, soweit rechtlich zulässig, dem am
                        nächsten kommt, was nach Sinn und Zweck der AGB gewollt war.
                    </p>
                </ContentBox>
            </PageLayout>
        </div>
    )
}

export default TermsPage
