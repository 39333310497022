export const getRoutes = (language: string, pathname: string, includeBase = false) => {
    pathname = pathname.replace('/de/', '/').replace('/en/', '/')

    const routes: Record<string, string> = {
        '/vielen-dank': '/thank-you',
        '/datenschutz': '/privacy',
        '/home': '/home',
        '/impressum': '/imprint',
        '/agb': '/terms',
    }

    // Reverse routes object
    const formatObject = (obj: Record<string, string>) =>
        Object.fromEntries(Object.entries(obj).map((a) => a.reverse()))

    switch (language) {
        case 'en':
            if (routes[pathname] === undefined) {
                return pathname
            }
            return (includeBase ? '/en' : '') + routes[pathname]
        case 'de':
            let newObj = formatObject(routes)
            if (newObj[pathname] === undefined) {
                return pathname
            }
            return (includeBase ? '/de' : '') + newObj[pathname]
        default:
            return pathname
    }
}
