import { useTranslation } from 'react-i18next'

interface IMenuItem {
    title: string
    path: string
    icon: string
    cName: string
    mobileOnly?: boolean
}

export const MenuItems = (): IMenuItem[] => {
    const { t } = useTranslation()

    return [
        {
            title: t('sidebarItems.expert'),
            path: '/#expert',
            icon: 'arrow_forward',
            cName: 'nav-text',
        },
        {
            title: t('sidebarItems.who-we-are'),
            path: '/#who-we-are',
            icon: 'arrow_forward',
            cName: 'nav-text',
        },
        {
            title: t('sidebarItems.references'),
            path: '/#references',
            icon: 'arrow_forward',
            cName: 'nav-text',
        },
        {
            title: t('sidebarItems.current-projects'),
            path: '/#current-projects',
            icon: 'arrow_forward',
            cName: 'nav-text',
        },
        {
            title: t('sidebarItems.what-we-need'),
            path: '/#what-we-need',
            icon: 'arrow_forward',
            cName: 'nav-text',
            mobileOnly: true,
        },
        {
            title: t('sidebarItems.what-we-offer'),
            path: '/#what-we-offer',
            icon: 'arrow_forward',
            cName: 'nav-text',
            mobileOnly: true,
        },
        {
            title: t('sidebarItems.what-you-need'),
            path: '/#what-you-need',
            icon: 'arrow_forward',
            cName: 'nav-text',
            mobileOnly: true,
        },
    ]
}
