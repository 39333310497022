import React from 'react'
import classNames from 'classnames'
import './Footer.scss'
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer'
import Routes from '../../../config/routes'
import { useTranslation } from 'react-i18next'
import { getRoutes } from '../../../config/getRoutes'

interface Props {
    className?: string
}

const Footer = (props: Props) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    return (
        <footer className={classNames('footer', props.className)}>
            <LayoutContainer>
                <ul className="footer__list wrapper">
                    <li className="footer__item">
                        <a className="footer__link" href={getRoutes(language, Routes.IMPRINT_PAGE, true)}>
                            {t('footer.imprint')}
                        </a>
                    </li>
                    <li className="footer__item">
                        <a className="footer__link" href={getRoutes(language, Routes.PRIVACY_PAGE, true)}>
                            {t('footer.privacy')}
                        </a>
                    </li>
                    <li className="footer__item">
                        <a className="footer__link" href={getRoutes(language, Routes.TERMS_PAGE, true)}>
                            {t('footer.terms')}
                        </a>
                    </li>
                    <li className="footer__item">
                        <a
                            className="footer__link"
                            href="https://skon.de/kontakt/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('footer.contact')}
                        </a>
                    </li>
                    <li className="footer__item">
                        <a
                            className="footer__link"
                            href="https://skon.de/karriere/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('footer.more-jobs')}
                        </a>
                    </li>
                </ul>
            </LayoutContainer>
        </footer>
    )
}

export default Footer
