// eslint-disable-next-line
import React from 'react'
import { useTranslation } from 'react-i18next'

const TranslationExists = (key: string): boolean => {
    const { t } = useTranslation()
    return t(key) !== key
}

export default TranslationExists
