import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../types/IRootState'
import PageLayout from '../organisms/PageLayout/PageLayout'
import './HomePage.scss'
import ContentBox from '../molecules/ContentBox/ContentBox'
import WizardProgress from '../atoms/WizardProgress/WizardProgress'
import './RegistrationPage.scss'
import RegistrationStep2 from '../organisms/RegistrationStep2/RegistrationStep2'
import RegistrationStep3 from '../organisms/RegistrationStep3/RegistrationStep3'
import RegistrationStep4 from '../organisms/RegistrationStep4/RegistrationStep4'
import RegistrationStep5 from '../organisms/RegistrationStep5/RegistrationStep5'
import setRegistrationStepAction from '../../state/actions/setRegistrationStep'

const RegistrationPage = () => {
    const dispatch = useDispatch()
    const indexOffset = 2
    const registrationStep = useSelector((state: IRootState) => state.form.registrationStep) as number

    const onSubmit = (step: number) => {
        dispatch(setRegistrationStepAction(step + 1))
        console.log('Update registrationStep to', step + 1)
    }

    const onStepUpdate = (updateStep: number) => {
        if (updateStep + indexOffset === registrationStep || updateStep + indexOffset === 5) return
        dispatch(setRegistrationStepAction(updateStep + indexOffset))
    }

    return (
        <div className="registration-page">
            <PageLayout>
                <ContentBox className="registration-page__content-box-progress" id="wizard">
                    <WizardProgress
                        screenReaderLabel="Fortschritt Registrierung"
                        steps={['Allgemein', 'Anschrift', 'Arbeitsmittel', 'Selbstständigkeit']}
                        activeStep={registrationStep - 2}
                        onClick={(updateStep) => onStepUpdate(updateStep)}
                    />
                </ContentBox>

                <ContentBox className="headline--blue" id="registration">
                    <div className="registration">
                        {registrationStep === 2 && <RegistrationStep2 onSubmit={() => onSubmit(2)} />}
                        {registrationStep === 3 && <RegistrationStep3 onSubmit={() => onSubmit(3)} />}
                        {registrationStep === 4 && <RegistrationStep4 onSubmit={() => onSubmit(4)} />}
                        {registrationStep === 5 && <RegistrationStep5 onSubmit={() => onSubmit(5)} />}
                    </div>
                </ContentBox>
            </PageLayout>
        </div>
    )
}

export default RegistrationPage
