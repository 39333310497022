import React, { useState } from 'react'
import classNames from 'classnames'
import { AppBar, Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar } from '@mui/material'
import Logo from '../../atoms/Logo/Logo'
import { MenuItems } from '../../../config/MenuItems'
import { useLocation, useNavigate } from 'react-router-dom'
import Hamburger from '../../atoms/Hamburger/Hamburger'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import './Header.scss'
import colors from '../../../scss/_colors.scss'
import { t } from 'i18next'
import DynamicLink from '../DynamicLink/DynamicLink'

interface Props {
    className?: string
    useAnchorLinks?: boolean
    window?: () => Window
}

const Header = (props: Props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const drawerWidth = 320
    const { window } = props
    const container = window !== undefined ? () => window().document.body : undefined
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const handleDrawerToggle = () => {
        setSidebarOpen(!sidebarOpen)
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Divider style={{ background: colors.lightgrey }} />
            <List
                disablePadding
                sx={{
                    // selected and (selected + hover) states
                    '&& .Mui-selected': {
                        bgcolor: 'transparent',
                        color: colors.blue,
                    },
                    '& svg': {
                        opacity: 0,
                    },
                    // hover states
                    '& .MuiListItemButton-root:hover': {
                        bgcolor: colors.blue,
                        color: colors.white,
                        '& svg': {
                            opacity: 1,
                        },
                    },
                    '& .MuiListItem-divider': {
                        borderBottom: `1px solid ${colors.lightgrey}`,
                        '&:last-child': {
                            borderBottom: 'none',
                        },
                    },
                }}
            >
                {MenuItems().map((item, index) => (
                    <ListItem key={index} disablePadding disableGutters divider>
                        <DynamicLink
                            useAnchorLink={props.useAnchorLinks ?? false}
                            href={item.path}
                            offset="100"
                            className="header-anchor-link-drawer"
                        >
                            <ListItemButton
                                disableGutters
                                style={{
                                    paddingLeft: 30,
                                    paddingTop: 15,
                                    paddingBottom: 15,
                                }}
                                selected={item.path === location.pathname}
                                onClick={() => item.path.substring(0, 1) !== '#' && navigate(item.path)}
                            >
                                <ListItemText
                                    primary={item.title}
                                    primaryTypographyProps={{
                                        fontFamily: 'Hind',
                                    }}
                                />
                                <svg
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        paddingRight: 30,
                                    }}
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="#fff"
                                        d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
                                    />
                                </svg>
                            </ListItemButton>
                        </DynamicLink>
                    </ListItem>
                ))}
            </List>
            <List
                disablePadding
                style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                }}
            ></List>
        </Box>
    )

    return (
        <>
            <AppBar
                elevation={0}
                component="nav"
                position="sticky"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <Toolbar disableGutters className="header">
                    <div className={classNames('header-container', 'layout-container')}>
                        <div onClick={handleDrawerToggle}>
                            <Logo />
                        </div>
                        <Hamburger
                            className={sidebarOpen ? 'toggler__icon toggle' : 'toggler__icon'}
                            onClick={handleDrawerToggle}
                        />
                        <Box
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                margin: 0,
                                padding: 0,
                            }}
                        >
                            {MenuItems()
                                .filter((item) => !item.mobileOnly)
                                .map((item, i) => (
                                    <DynamicLink
                                        key={i}
                                        useAnchorLink={props.useAnchorLinks ?? false}
                                        href={item.path}
                                        offset="100"
                                        className="header-active-nav header-anchor-link"
                                    >
                                        {item.title}
                                    </DynamicLink>
                                ))}
                            {/*<div className="header-active-nav">*/}
                            {/*    <ListItemText*/}
                            {/*        primary={'Login'}*/}
                            {/*        primaryTypographyProps={{*/}
                            {/*            fontFamily: 'Hind',*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <DynamicLink useAnchorLink={props.useAnchorLinks ?? false} href="#registration">
                                <PrimaryButton className="header__register-button">{t('expert-cta')}</PrimaryButton>
                            </DynamicLink>
                        </Box>
                    </div>
                </Toolbar>
            </AppBar>

            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor="right"
                    open={sidebarOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            background: colors.backgroundgray,
                        },
                        '& 	.MuiBackdrop-root': {
                            backgroundColor: 'rgba(33, 51, 58, 0.44)',
                        },
                    }}
                >
                    <Toolbar style={{ paddingTop: 24 }} />
                    {drawer}
                </Drawer>
            </Box>
        </>
    )
}

export default Header
