import classNames from 'classnames'
import React from 'react'
import LayoutContainer from '../LayoutContainer/LayoutContainer'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import './FloatingButton.scss'
import AnchorLink from 'react-anchor-link-smooth-scroll'

interface Props {
    className?: string
    buttonName?: string
    onClick?: (e: React.MouseEvent) => void
}

const FloatingButton = (props: Props) => {
    return (
        <div
            className={classNames('floating-button-container', props.className)}
        >
            <LayoutContainer>
                <PrimaryButton
                    className="floating-button__layout-container"
                    onClick={props.onClick}
                >
                    <AnchorLink offset={100} href="#registration">
                        {props.buttonName}
                    </AnchorLink>
                </PrimaryButton>
            </LayoutContainer>
        </div>
    )
}
export default FloatingButton
