import { actionIds, BaseAction } from '../common'
import { IConfigState } from '../../types/IConfigState'

const defaultState: IConfigState = {}

const configReducer = (
    state: IConfigState = defaultState,
    action: BaseAction
): IConfigState => {
    switch (action.type) {
        case actionIds.SET_CONFIG:
            return {
                ...state,
                ...action.payload,
            }

        default:
            return state
    }
}

export default configReducer
