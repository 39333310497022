import { TFormAction } from '../types/IFormState'
// https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData

interface NavigatorWithUAData extends Navigator {
    userAgentData?: {
        brands: Array<{ brand: string; version: string }>
        mobile?: boolean
        platform?: string
        architecture?: string
        getHighEntropyValues: (keys: TEntropyKeys[]) => Promise<Record<TEntropyReturnValues, string>>
    }
    deviceMemory?: number
}

interface IHardwareInfo {
    has_recent_os: boolean
    has_sufficient_ram: boolean
    has_sufficient_resolution: boolean
}

type TEntropyKeys = 'architecture' | 'model' | 'platform' | 'platformVersion' | 'fullVersionList'

type TEntropyReturnValues =
    | 'brands'
    | 'mobile'
    | 'platform'
    | 'mobile'
    | 'architecture'
    | 'bitness'
    | 'model'
    | 'platformVersion'
    | 'fullVersionList'

export const hardwareInfo = async () => {
    let hardwareInfo: IHardwareInfo = {
        has_recent_os: false,
        has_sufficient_ram: false,
        has_sufficient_resolution: false,
    }

    const navigatorWithUAData = navigator as NavigatorWithUAData
    const platform = navigatorWithUAData.userAgentData?.platform
    const deviceMemory = navigatorWithUAData.deviceMemory ?? 0

    const ua = await navigatorWithUAData.userAgentData?.getHighEntropyValues([
        'architecture',
        'model',
        'platformVersion',
        'fullVersionList',
    ])

    if (platform === 'Windows') {
        const majorPlatformVersion = ua ? parseInt(ua.platformVersion.split('.')[0]) : 0

        if (majorPlatformVersion >= 13) {
            // Windows 11 or later
            hardwareInfo.has_recent_os = true
        } else if (majorPlatformVersion > 0) {
            // Windows 10
            hardwareInfo.has_recent_os = true
        } else {
            // Before Windows 10
            hardwareInfo.has_recent_os = false
        }
    }

    if (platform === 'macOS' || platform === 'Linux') {
        hardwareInfo.has_recent_os = true
    }

    if (deviceMemory >= 8) {
        hardwareInfo.has_sufficient_ram = true
    }

    if (window.screen.width >= 1280 && window.screen.height >= 1024) {
        hardwareInfo.has_sufficient_resolution = true
    }

    return hardwareInfo
}

export const boolToYesUnknown = (value: boolean): TFormAction => {
    return value ? 'yes' : 'unknown'
}
